import { VideoMComponent } from './recycle-comps/recipe/mobile-recipe/video-m/video-m.component';

import { NMobileRecipeComponent } from './recycle-comps/n-mobile-recipe/n-mobile-recipe.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { StartQuestionareComponent } from './recycle-comps/start-questionare/start-questionare.component';
import { RecipesComponent } from './recycle-comps/recipes/recipes.component';
import { FeaturedRecipesComponent } from './recycle-comps/featured-recipes/featured-recipes.component'
import { AppComponent } from './app.component';
import { BodyComponent } from './structure-comps/body/body.component';
import { HeaderComponent } from './structure-comps/header/header.component';
import { FooterComponent } from './structure-comps/footer/footer.component';
import { PizzaBuilderSimpleComponent } from './recycle-comps/pizza-builder-simple/pizza-builder-simple.component';
import { WelcomeToMunchComponent } from './recycle-comps/welcome-to-munch/welcome-to-munch.component';
import { ExperimentalComponent } from './recycle-comps/experimental/experimental.component';
import { TimeCategoriesComponent } from './recycle-comps/time-categories/time-categories.component';
import { StartExploringComponent } from './recycle-comps/start-exploring/start-exploring.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './structure-comps/home/home.component';
import { MobileRecipeComponent } from './recycle-comps/recipe/mobile-recipe/mobile-recipe.component';
import { RecipeComponent } from './recycle-comps/recipe/recipe.component';
import { StepByStepComponent } from './recycle-comps/recipe/mobile-recipe/step-by-step/step-by-step.component';
import { AboutComponent } from './recycle-comps/about/about.component';
import { WorkingComponent } from './recycle-comps/working/working.component';
import { WeeklyBlogComponent } from './recycle-comps/weekly-blog/weekly-blog.component';
import { MobileRecipesComponent } from './recycle-comps/recipes/mobile-recipes/mobile-recipes.component';
import { BlogComponent } from './recycle-comps/weekly-blog/blog/blog.component';
import { SimpleRecipeDComponent } from './recycle-comps/recipe/simple-recipe-d/simple-recipe-d.component';
import { StepByStepDComponent } from './recycle-comps/recipe/step-by-step-d/step-by-step-d.component';
import { VideoDComponent } from './recycle-comps/recipe/video-d/video-d.component';
import { SimpleRecipeComponent } from './recycle-comps/recipe/mobile-recipe/simple-recipe/simple-recipe.component';
import { NotFoundComponent } from './recycle-comps/not-found/not-found.component';

import { IngNormalMeasComponent } from './recycle-comps/ingredient/measures/needed/ing-normal-meas/ing-normal-meas.component';
import { IngItemMeasComponent } from './recycle-comps/ingredient/measures/needed/ing-item-meas/ing-item-meas.component';
import { IngIrregMeasComponent } from './recycle-comps/ingredient/measures/needed/ing-irreg-meas/ing-irreg-meas.component';
import { IngItemOfMeasComponent } from './recycle-comps/ingredient/measures/needed/ing-itemof-meas/ing-itemof-meas.component';
import { IngDetailMeasComponent } from './recycle-comps/ingredient/measures/needed/ing-detail-meas/ing-detail-meas.component';

import { IngredientsComponent } from './recycle-comps/ingredients/ingredients.component';
 

import { IngNormalMeasComponentOpt } from './recycle-comps/ingredient/measures/optional/ing-normal-meas-opt/ing-normal-meas.component';
import { IngItemMeasComponentOpt } from './recycle-comps/ingredient/measures/optional/ing-item-meas-opt/ing-item-meas-opt.component'
import { IngDetailMeasComponentOpt } from './recycle-comps/ingredient/measures/optional/ing-detail-meas-opt/ing-detail-meas-opt.component';
import { IngItemOfMeasComponentOpt } from './recycle-comps/ingredient/measures/optional/ing-itemof-meas-opt/ing-itemof-meas-opt.component';
import { IngIrregMeasComponentOpt } from './recycle-comps/ingredient/measures/optional/ing-irreg-meas-opt/ing-irreg-meas-opt.component';
import { ShareBtnsComponent } from './recycle-comps/share-btns/share-btns.component';
import { UtensilsComponent } from './recycle-comps/utensils/utensils.component';
import { CountryRecipesComponent } from './recycle-comps/country-recipes/country-recipes.component';



@NgModule({
    declarations: [
    AppComponent,
    BodyComponent,
    HeaderComponent,
    FooterComponent,
    PizzaBuilderSimpleComponent,
    FeaturedRecipesComponent,
    WelcomeToMunchComponent,
    RecipesComponent,
    ExperimentalComponent,
    TimeCategoriesComponent,
    StartQuestionareComponent,
    StartExploringComponent,
    HomeComponent,
    MobileRecipeComponent,
    RecipeComponent,
    StepByStepComponent,
    AboutComponent,
    WorkingComponent,
    WeeklyBlogComponent,
    MobileRecipesComponent,
    BlogComponent,
    SimpleRecipeDComponent,
    StepByStepDComponent,
    VideoDComponent,
    NMobileRecipeComponent,
    SimpleRecipeComponent,
    NotFoundComponent,
    IngNormalMeasComponent,
    IngItemMeasComponent,
    IngIrregMeasComponent,
    IngItemOfMeasComponent,
    IngDetailMeasComponent,
    IngredientsComponent,
    IngNormalMeasComponentOpt,
    IngItemMeasComponentOpt,
    IngDetailMeasComponentOpt,
    IngItemOfMeasComponentOpt,
    IngIrregMeasComponentOpt,
    ShareBtnsComponent,
    VideoMComponent,
    UtensilsComponent,
    CountryRecipesComponent
    ],

    imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule
    ],

    providers: [],
    bootstrap: [AppComponent]
})

// *********************************************************************************************

export class AppModule  { 

// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
