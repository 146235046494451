import { RecipesService } from './../../services/recipes.service';
import { Component, OnInit, Injectable, OnChanges, AfterViewInit, DoCheck } from '@angular/core';
import * as $ from 'jquery';



@Component({
  selector: 'featured-recipes',
  templateUrl: './featured-recipes.component.html',
  styleUrls: ['./featured-recipes.component.css', './responsive-featured-recipes.component.css']
})

@Injectable()
export class FeaturedRecipesComponent implements OnInit, OnChanges, AfterViewInit, DoCheck{

  constructor(private RecipesService:RecipesService) { }

// ************************************************
  // STATIC VARIABLES

  recipes:any[]=this.RecipesService.retrieveFeaturedRecipes();
  ajiaco:any= this.recipes[0];
  myDesc:string=this.ajiaco.recipeDescription;
  activeSlide:number= 1;
  slides:any;
  userMovement:boolean=false;
  lastUserMovement:number;
  automaticSlide:number;
  
  

// ************************************************
//LIFECYCLES

ngOnInit() { 
  // this.slideshowAutomatic()
}

// ------------------------------------------------------------

ngDoCheck(){
  if(this.userMovement ==true){
    this.slideshowAutomatic()
  }
}

// ------------------------------------------------------------  

ngAfterViewInit(){
  this.fullifyRecipes();  
  this.slides = document.querySelectorAll(".featuredRecipesCont");
  this.updateSlideshow(this.activeSlide);
  this.updateRecipesService();
}

// ------------------------------------------------------------  

ngOnChanges(){
  this.checkMyVariable();
}

// *********************************************************************************************
//STATIC FUNCTIONS


checkMyVariable(){
  let toLog = this.RecipesService.retrieveActiveRecipe();
  console.log(toLog);
}

// ------------------------------------------------------------

fullifyRecipes(){

    let featuredRecipeImgs = document.getElementsByClassName("featuredRecipeImg");
    let recipeCountryImgs = document.getElementsByClassName("recipeCountryImg");
    let recipeTypeImgs = document.getElementsByClassName("recipeTypeImg");
    let recipeIngredients = document.getElementsByClassName("recipeIngredientImg");
    let featuredRecipesConts = document.getElementsByClassName("featuredRecipesCont");


    this.pictureChangeWithArray(featuredRecipeImgs, "food/startRecipes", "jpg");
    this.pictureChangeWithArray(recipeCountryImgs, "flags", "png");
    this.pictureChangeWithArray(recipeTypeImgs, "featured-recipes.comp/recipeTypes", "png");
    this.pictureChangeWithArray(recipeIngredients, "food/ingredients", "jpg");

    for (let i = 0; i < featuredRecipesConts.length; i++) {
        const element = featuredRecipesConts[i];

        if(i % 2 == 0){
          $(element).css("background-color", "#e6e600");
        }else{
            let number = Math.floor(Math.random() * 11); 

            if(number <= 3 && number >= 0){
              $(element).css("background", "linear-gradient(#ffb31a,#e6e600)");
            }else if(number <= 6 && number >= 3){
              $(element).css("background", "linear-gradient(#95d611,#e6e600)");
            }
        }
    }

}

// ------------------------------------------------------------

pictureChangeWithArray(theArray, folderName, fileType){

  $.each(theArray, (index, element)=>{
    let theName = $(element).text();
    let picName = theName.replace(/ /g, "").toLowerCase();
    $(element).css("background-image", "url(../../../imgs/"+folderName+"/"+picName+"."+fileType+")");
    $(element).text("");
  })

}

// ------------------------------------------------------------

// Next/previous controls
plusSlides(number) {
  this.updateSlideshow(this.activeSlide += number);
}

// ------------------------------------------------------------

minusSlides(number) {
  this.updateSlideshow(this.activeSlide -= number);
}

// ------------------------------------------------------------

// Thumbnail dots image controls
currentSlide(number) {
  this.updateSlideshow(this.activeSlide = number);
}

// ------------------------------------------------------------

updateSlideshow(number) {
    var numberToWorkWith:number;
    var dots = document.getElementsByClassName("dot");


    // Logic Implementation
    if (number > this.slides.length) {this.activeSlide = 1} // Go to first slide if next is called from last slide
    if (this.activeSlide < 1) {this.activeSlide = this.slides.length} //Go to last slide if prev is called from slide 1

    for (var i = 0; i < this.slides.length; i++) {
      $(this.slides[i]).css("display", "none");
    }

    // Dots Styling
    for (var i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }

    $(this.slides[this.activeSlide-1]).css("display", "flex");
    $(dots[this.activeSlide-1]).addClass("active");
    this.updateRecipesService();

  }

// ------------------------------------------------------------

slideshowAutomatic(){
    if(this.userMovement == false){
        this.updateRecipesService();
        setTimeout(() => {
        console.log("CHANGE")
        if(this.userMovement == false){
            this.updateSlideshow(this.activeSlide += 1);
            this.slideshowAutomatic();
            this.updateRecipesService();
        }else{
            return;
        }
        }, 4500);
    }else{
        return
    }
 }

// ------------------------------------------------------------

onUserMoveSlideshow(waitTime:number){

    this.userMovement = true;
    this.updateRecipesService()
    this.updateSlideshow(this.activeSlide);

    setTimeout(() => {
        this.userMovement = false;
        this.slideshowAutomatic();
    }, waitTime);

}

// ------------------------------------------------------------

updateRecipesService(){
  this.RecipesService.activeRecipe = this.activeSlide;
}



// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS