import { GlobalService } from './../global.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';

@Injectable({ 
    providedIn: 'root'
  })

export class ScreenSizeDesktopGuard implements CanActivate{

    constructor(private GlobalService:GlobalService,
                        private myRouter:Router,
                        private myRoute:ActivatedRoute){}

canActivate(route:ActivatedRouteSnapshot, routerState:RouterStateSnapshot)
:Observable<boolean> | Promise<boolean> | boolean{
    const myScreenWidth = this.GlobalService.retrieveScreenWidth();

    if(myScreenWidth >= 1030 ){;
      return true
    }else{
      this.myRouter.navigate(['/recipe-m', route.params['recipeId']])
    }
}


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS