import { GlobalService } from './../../../../services/global.service';
import { RecipesService } from './../../../../services/recipes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import * as $ from 'jquery'

@Component({
  selector: 'video-m',
  templateUrl: './video-m.component.html',
  styleUrls: ['./video-m.component.css'] 
})

export class VideoMComponent implements OnInit, AfterViewInit{

constructor(private ActivatedRoute:ActivatedRoute,
                    private RecipesService:RecipesService, 
                    private GlobalService:GlobalService,
                    private sanitizer: DomSanitizer, 
                    private Router: Router) { }

// *********************************************************************************************

myRecipeId:any; 
myRecipeObj:any;
servingNumbers:any[]=[];
peopleAmt:any;
myYtUrl:any;
myNormalUrl:any;

// *********************************************************************************************

ngOnInit() {

  for (let x= 0; x < 100; x++) {
    if(x !== 0){
      this.servingNumbers[x] = x;    
    }
  }

  if(this.ActivatedRoute.snapshot.params['recipeId']){
    this.myRecipeId = this.ActivatedRoute.snapshot.params['recipeId']
    this.myRecipeObj = this.RecipesService.theRecipes[this.myRecipeId - 1]
    this.peopleAmt = this.myRecipeObj.pplPerRecipe;
    this.myYtUrl = "https://www.youtube.com/embed/" + this.myRecipeObj.ytVideoUrl ; 
    this.myNormalUrl = this.myRecipeObj.videoUrl ; 
    console.log(this.myNormalUrl)
  }else{
    console.error("Error 69:// NO ID HAS BEEN PASSED")
  }
  
console.log(this.myRecipeObj)

}


ngAfterViewInit(){
    //...........................................................................................
//Remove UNDEFINED element from select dropdown
const mySelects:any= $(".mySelectOpt");
for (let i = 0; i < mySelects.length; i++) {
  const element = mySelects[i];
  if(element.value == 'undefined'){
    this.GlobalService.removeElement(element);
  }
}

}


// ----------------------------------------------------------------------------------------

updateIngAmounts(myEvent: any ){
  let mySelect:any = myEvent.srcElement;

  if(mySelect.value == 'undefined'){
    mySelect.value = 1;
  }else if(mySelect.value == 'undefined' && this.peopleAmt !== 1){
    this.peopleAmt = mySelect.value;
  }else{
    this.peopleAmt = mySelect.value;
  }
}

// ----------------------------------------------------------------------------------------

cleanURL(oldURL): SafeUrl {
  return   this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
}

//------------------------------------------------------------------------------

onBackArrow(){
  this.Router.navigate(['recipe-m', this.myRecipeId]);
}
 


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
