import { GlobalService } from './../../services/global.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'start-exploring',
  templateUrl: './start-exploring.component.html',
  styleUrls: ['./../welcome-to-munch/welcome-to-munch.component.css', './../welcome-to-munch/responsive-welcome-to-munch.component.css']
})

export class StartExploringComponent implements OnInit {

  constructor(private GlobalService:GlobalService) { }
  
// *********************************************************************************************
//STATIC VARIABLES



// *********************************************************************************************
//LIFECYCLE HOOKS

ngOnInit() {

}

// *********************************************************************************************
//STATIC FUNCTIONS

scrollUp(){
  this.GlobalService.scrollTo(0,0)
}





// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
