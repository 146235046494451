import { Injectable, Output } from '@angular/core';
import recipes from './../exceptions/recipes.json'
import ingredients from './../exceptions/ingredients.json'
import steps from './../exceptions/steps.json'
import myFeaturedRecipes from './../exceptions/featuredRecipes.json'
import allRecipes from './../exceptions/allRecipes.json'

@Injectable({
  providedIn: 'root'
})
export class RecipesService {
 
  constructor() { }
  public activeRecipe:number;
  public myActiveCategoryClicked:any;


  theRecipes = recipes;
  theIngredients = ingredients;
  recipeSteps = steps;
  featuredRecipes= myFeaturedRecipes;
  theReciped = allRecipes;
      

    retrieveRecipes(){
    return this.theRecipes;
  }

  retrieveIngredients(){
    return this.theIngredients;
  }

  retrieveFeaturedRecipes(){
    return this.featuredRecipes;
  }

  retrieveActiveRecipe(){
    return this.activeRecipe;
  }
   
  getRandomRecipes() {
    const randomRecipesArray = Array.from(this.theRecipes);
    var currentIndex = randomRecipesArray.length, temporaryValue:any, randomIndex:any;
  
    // While there are elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap the currentIndex value with the random element.
      temporaryValue = randomRecipesArray[currentIndex];
      randomRecipesArray[currentIndex] = randomRecipesArray[randomIndex];
      randomRecipesArray[randomIndex] = temporaryValue;
    }
  
    return randomRecipesArray;
  }




// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS


