import { DishCategory } from '../models/dish-category.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CategoriesService {

  constructor() { }

  public snack: DishCategory[]= [
    new DishCategory('sandwiches', ''),
    new DishCategory('chicken wings', ''),
    // new DishCategory('empanadas', ''),
    // new DishCategory('hot drinks', ''),
    new DishCategory('nachos', ''),
    new DishCategory('desserts', '')
  ];

  public breakfast: DishCategory[]=[
    new DishCategory('eggs', ''),
    // new DishCategory('drinks', ''),
    // new DishCategory('bakery', ''),
    new DishCategory('cereals', ''),
    new DishCategory('pancakes', ''),
    new DishCategory('bacon dishes', ''),
    new DishCategory('sandwiches', '')


  ];

  public brunch: DishCategory[]=[
    new DishCategory('sandwiches', ''),
    // new DishCategory('drinks', ''),
    new DishCategory('salads', ''),
    new DishCategory('soups', '')
  ];

  public lunch:DishCategory[]=[
    new DishCategory('entrees', ''),
    new DishCategory('steak', ''),
    new DishCategory('fishes', ''),
    new DishCategory('vegetarian', ''),
    new DishCategory('rices', ''),
    // new DishCategory('drinks', ''),
    // new DishCategory('pizzas', ''),
    new DishCategory('burgers', ''),
    new DishCategory('desserts', '')
  ];  

  public dinner: DishCategory[]=[
    new DishCategory('pastas', ''),
    new DishCategory('lasagnas', ''),
    // new DishCategory('pizzas', ''),
    new DishCategory('salads', ''),
    // new DishCategory('noodles', ''),
    new DishCategory('sandwiches', '')
  ];  

  public RecipeCategories:any[]=[
    {
      'name':'step by step',
      'description': 'We know cooking can get pretty complicated! Step By Step gives you an extra hand when you seek further explaination for different techniques and ingredients in the recipe.'
    },
    {
      'name':'simple recipe',
      'description': 'When you only need exact instructions and an ingredient list, this is the best option! Freely switch between any instructions in the recipe and get cooking.'
    },
    {
      'name':'video',
      'description': 'What\'s  better than being taught how to do something with a perfect visual representation? Having someone explain every step of the way, just press play and get cooking.'
    },

  ]




// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
