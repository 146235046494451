import { GlobalService } from './../../services/global.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private myActivatedRoute:ActivatedRoute, 
                      private GlobalService:GlobalService) { }

// *********************************************************************************************
//STATIC VARIABLES


// *********************************************************************************************
//LIFECYCLE HOOKS

ngOnInit() {
}

// *********************************************************************************************
//STATIC FUNCTIONS

scrollToTop(){
  this.GlobalService.scrollTo(0,0)
}



// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
