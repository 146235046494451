import { ActivatedRoute, Router, Params } from '@angular/router';
import { OnInit, Component, AfterViewInit, OnDestroy, DoCheck } from '@angular/core';
import { Title } from '@angular/platform-browser';
import myOgRecipes from "./../../exceptions/recipes.json"

import { CountryContinentService } from './../../services/country-continent.service';
import * as $ from 'jquery';

import { GlobalService } from './../../services/global.service';
import { RecipesService } from './../../services/recipes.service';

@Component({
    selector: "recipes",
    templateUrl:"./recipes.component.html",
    styleUrls:["./recipes.component.css", "./responsive-recipes.component.css"]
})

export class RecipesComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck{

    constructor( private RecipesService:RecipesService,
                        private GlobalService:GlobalService,
                        private route:ActivatedRoute,
                        private router:Router,
                        private CountryContinentService:CountryContinentService,
                        private TitleService:Title){};

// *********************************************************************************************
//STATIC VARIABLES


        math:any= Math;
        noResultsBool:boolean=false;
        recipes:any= this.RecipesService.getRandomRecipes();
        theRecipes = myOgRecipes;
        ingredients:any=this.RecipesService.retrieveIngredients();
        sidebarVisible:boolean= false;
        recipesCheckboxActive:boolean=true;
        ingredientsCheckboxActive:boolean=false; 
        recipeLevels_arr:any[]=[];
        outConts:any;
        minValTimeFilter:any=15;
        maxValTimeFilter:any=30;
        searchbarValue:string="";
        searchbarPlaceholder:string;
        filterCookingLevel_bool:boolean=false; 
        filterCookingTime_bool:boolean=false;
        searchBannerBool:boolean=false;

// *********************************************************************************************
//LIFECYCLES

ngOnInit(){

    this.TitleService.setTitle("MunCH!  · Recipes")


    this.displayUtensilsFunc('need');

if(this.route.snapshot.params['category']){
    this.searchbarValue =  this.route.snapshot.params['category'];

    this.route.params.subscribe((theParams: Params)=>{
    this.searchbarValue = theParams['category'];
    })

}

// $.each(this.recipes, (index, val)=>{
//     this.getMyContinent(this.recipes[index])
// })

}

//------------------------------------------------------------------------------

ngDoCheck(){
this.fixSearchBannerBool()
}

//------------------------------------------------------------------------------

ngAfterViewInit(){

    let recipeConts = document.getElementsByClassName("recipeOuterCont");
    let ingredientConts = document.getElementsByClassName("ingredientOuterCont");
    let ingsCont = document.getElementById("ingredientsCont");
    this.outConts = document.getElementsByClassName("outCont");
    this.imagePlacementRecipe(recipeConts);
    this.imagePlacementIngredient(ingredientConts);
    this.imagePlacementInNameFunc("countryImg", "../../../imgs/location/countries/", "png")
    this.continentSelectionFunc();

    
    this.recipeLoopFunc(this.outConts);

    if(this.route.snapshot.params['category']){
        this.onSearchbarKeyup("myEvent", document.getElementById('theSearchbar'));
    }

    $(ingsCont).fadeOut(1);

    
  }

//------------------------------------------------------------------------------

  ngOnDestroy(){
    if(this.sidebarVisible){
        this.sidebarToggle()
    }
  }

// *********************************************************************************************

//STATIC FUNCTIONS

imagePlacementRecipe(theArray:any){

        for (let i = 0; i < theArray.length; i++) {
        const element = theArray[i];
        const grayCont = element.firstChild;
        const recipeHeader = grayCont.children[0];
        const recipeName = recipeHeader.textContent.replace(/ /g, '').toLowerCase();

        $(element).css("background-image", "url(../../../imgs/food/startRecipes/"+recipeName+".jpg)");
        }
}

//------------------------------------------------------------------------------

imagePlacementIngredient(theArray:any){

        for (let i = 0; i < theArray.length; i++) {
        const element = theArray[i];
        const grayCont = element.firstChild;
        const ingHeader = grayCont.children[0];
        const ingName = ingHeader.textContent.replace(/ /g, '').toLowerCase().replace(/ñ/g, 'n');

        $(element).css("background-image", "url(../../../imgs/food/ingredients/"+ingName+".jpg)");

        if(ingName == "cocacola"){
            $(ingHeader).text("")
        }
        }
        
}

//------------------------------------------------------------------------------
 
showRecipeInfo(theEvent:MouseEvent){
 
        let element:any= theEvent.srcElement;

        if(element.tagName == "H2"){
            let grayCont = element.parentElement;
            let infoCont = grayCont.children[1];

            $(element).css("display", "none");
            $(infoCont).css("display", "flex");
            $(grayCont).css("background", "rgba(255, 255, 255, .8)");
        }else if(element.tagName == "DIV" && element.className == "recipeGrayCont"){
            let header = element.children[0];
            let infoCont = element.children[1];

            $(header).css("display", "none");
            $(infoCont).css("display", "flex");
            $(element).css("background", "rgba(255, 255, 255, .8)");
        }

}

//------------------------------------------------------------------------------

showRecipeName(theEvent:MouseEvent){

        let element:any = theEvent.srcElement;
        let infoCont;

        if($(element).hasClass("infoChild")){
            infoCont = element.parentElement;
            let grayCont = infoCont.parentElement;
            let recipeHeader = grayCont.children[0];

            $(infoCont).css("display", "none");
            $(recipeHeader).css("display", "block");
            $(grayCont).css("background", "rgba(255, 255, 255, .5)");

        }else if($(element).hasClass("infoCont")){
            infoCont = element;
            let grayCont = infoCont.parentElement;
            let recipeHeader = grayCont.children[0];

            $(infoCont).css("display", "none");
            $(recipeHeader).css("display", "block");
            $(grayCont).css("background", "rgba(255, 255, 255, .5)");

        }else if($(element).hasClass("recipeGrayCont")){
            infoCont = element.children[1];
            let grayCont = infoCont.parentElement;
            let recipeHeader = grayCont.children[0];

            $(infoCont).css("display", "none");
            $(recipeHeader).css("display", "block");
            $(grayCont).css("background", "rgba(255, 255, 255, .5)");
        }else if( element.tagName == "H3" || 
                $(element).hasClass("levelImg") ||
                    $(element).hasClass("timeImg") ||
                        $(element).hasClass("countryImg")   ){
            let infoChild = element.parentElement;
            infoCont = infoChild.parentElement;
            let grayCont = infoCont.parentElement;
            let recipeHeader = grayCont.children[0];

            $(infoCont).css("display", "none");
            $(recipeHeader).css("display", "block");
            $(grayCont).css("background", "rgba(255, 255, 255, .5)");

        }else if(element.tagName == "H2" && $(element).hasClass("recipeHeader")){
        
        }else{
        console.error("ERROR 601 --  Element Not Found!")
        }

}

//------------------------------------------------------------------------------

recipeLoopFunc(theArray){

        for (let i = 0; i < theArray.length; i++) {
            const element = theArray[i];  
            const elementFlag = element.getElementsByClassName('countryImg')[0];
          
            // console.log(element);

            element.addEventListener('mouseover', ()=>{
                let recipeOuterCont = element.firstChild;
                let grayCont = recipeOuterCont.firstChild;
                let recipeHeader = grayCont.firstChild;
                let myRecipeName = recipeHeader.textContent;
                let infoCont = grayCont.children[1];

                $(recipeHeader).css("display", "none");
                $(infoCont).css("display", "flex");
                $(grayCont).css("background", "rgba(255, 255, 255, .85)");
            }, false);


            element.addEventListener('mouseleave', ()=>{
                let recipeOuterCont = element.firstChild;
                let grayCont = recipeOuterCont.firstChild;
                let recipeHeader = grayCont.firstChild;
                let myRecipeName = recipeHeader.textContent;
                let infoCont = grayCont.children[1];

                $(infoCont).css("display", "none");
                $(recipeHeader).css("display", "block"); 
                $(grayCont).css("background", "rgba(255, 255, 255, .5)");
            }, false);
        }

}

//------------------------------------------------------------------------------

sidebarToggle(){

        const sidebar = $("#sidebar");
        const  recipesFilter = $("#recipesFilter");
        const recipesCont = $("#recipesCont");
        const ingredientsCont = $("#ingredientsCont");
        const categoryFlexCont = $("#categoryFlexCont");  
        const theSearchbar  = $("#theSearchbar");  
        const munchNav = $("#munchNavHeader")
        const munchNavUl = $("#munchNavHeaderUlCont");
        const threeBars = $("#threeBarNavCont");
        let outConts = $(".outCont")
        const overallOutCont = document.getElementById("overallOutCont");
        let it = this;

        if(this.sidebarVisible == false ){ 
            $(sidebar).addClass("sidebar_so");
            $(recipesFilter).addClass("sidebarOpenCategory");
            $(categoryFlexCont).addClass("sidebarOpenCategory");
            $(outConts).addClass("outCont_so");
            $(theSearchbar).addClass("theSearchbar_so");
            $(munchNav).addClass("munchNavHeader_so");
            $(munchNavUl).addClass("munchNavUl_so");
            $(threeBars).addClass("threeBars_so");
            $(ingredientsCont).css("height", "min-content");
            this.sidebarVisible = !this.sidebarVisible;  
        }else if(this.sidebarVisible == true){ 
            $(sidebar).removeClass("sidebar_so");
            $(recipesFilter).removeClass("sidebarOpenCategory");
            $(categoryFlexCont).removeClass("sidebarOpenCategory");
            $(theSearchbar).removeClass("theSearchbar_so");
            $(munchNav).removeClass("munchNavHeader_so");
            $(munchNavUl).removeClass("munchNavUl_so");
            $(threeBars).removeClass("threeBars_so");
            $(munchNavUl).show();
            $(outConts).removeClass("outCont_so");
            this.sidebarVisible = !this.sidebarVisible;  

            if(this.GlobalService.wWidth <= 1030){
                this.GlobalService.dropNavOpen = true;
            }
        }else{
            console.error("Something is wrong! Check the src code now =D")
        }
        
}

//------------------------------------------------------------------------------

onSearchbarKeyup(myEvent:any, mySearchbar?:any){
        
        let searchbar;
        let mySearchResultsNumber = 0;

        if(mySearchbar !== undefined){
            searchbar= mySearchbar;
        }else{
            searchbar = myEvent.srcElement;
        }

        let searchVal = searchbar.value;
        let recipeConts = $(".outCont");
        this.searchbarValue = searchVal;

        if(this.filterCookingLevel_bool == true && this.recipeLevels_arr.length >= 1){ //If Level Filter is up & running
   
            if(searchVal == "" && searchVal.length == 0){
                this.filterRecipes();
            }
            else if(searchVal.length >= 1){
                this.filterRecipes(searchVal);
            }
        }else{ //If level filter is off
            
        if(searchVal == "" && searchVal.length == 0){ //If nothing is being searched (searchbar is empty)
            $(recipeConts).css("display", "block")
        }else if(searchVal.length >= 1){  //If something is being searched 

            // Loop through all derCont items, and hide those who don't match the search query
            for (let i = 0; i < recipeConts.length; i++) {
                let element = recipeConts[i];
                let keywordsElement = element.getElementsByTagName("H6")[0];
                let txtValue = keywordsElement.textContent || keywordsElement.innerText;

                if (txtValue.toUpperCase().indexOf(searchVal.toUpperCase()) > -1) {
                    element.style.display = "block";
                    mySearchResultsNumber++
                } else {
                    element.style.display = "none";
            } }; // END OF LOOP
            


            if(mySearchResultsNumber == 0){ //If there are no search results!
            //    console.log("No results ∂∂∂");
               this.noResultsBool = true;
            }else{
                // console.log("Clean");
                this.noResultsBool = false;
            }
        }
        }

}

//------------------------------------------------------------------------------

updateVisibleRecipes(theEvent:any){

        const recipesCheckbox:any= document.getElementById("recipesCheckbox");
        const ingredientsCheckbox:any= document.getElementById("ingredientsCheckbox");
        const recipesCont = document.getElementById("recipesCont");
        const ingredientsCont = document.getElementById("ingredientsCont");
        let clickedCheckbox= theEvent.srcElement;
        let groupName:string= clickedCheckbox.name.toUpperCase();

        if(groupName == "INGREDIENTS" && recipesCheckbox.disabled == true){
            $(recipesCheckbox).removeAttr("disabled");
            $(recipesCont).fadeIn(500);
            $(ingredientsCont).fadeIn(500);
            $(recipesCont).css("width", "55%");
            $(ingredientsCont).css("width", "45%");
            $(recipesCont).css("height", "min-content");
        }else if(groupName == "INGREDIENTS" && recipesCheckbox.disabled == false){
            $(recipesCheckbox).attr("disabled", "disabled");
            $(recipesCont).fadeIn(500);
            $(ingredientsCont).fadeOut(500);
            $(recipesCont).css("width", "100%");
            $(recipesCont).css("height", "auto");
            $(ingredientsCont).css("width", "100%");
        }


        if(groupName == "RECIPES" && ingredientsCheckbox.disabled == true){
            $(ingredientsCheckbox).removeAttr("disabled");
            $(recipesCont).fadeIn(500);
            $(ingredientsCont).fadeIn(500);
            $(recipesCont).css("width", "55%");
            $(ingredientsCont).css("width", "45%");
            $(recipesCont).css("height", "min-content");
        }else if(groupName == "RECIPES" && ingredientsCheckbox.disabled == false){
            $(ingredientsCheckbox).attr("disabled", "disabled");
            $(recipesCont).fadeOut(500);
            $(ingredientsCont).fadeIn(500);
            $(recipesCont).css("width", "100%");
            $(ingredientsCont).css("width", "100%");
            $(recipesCont).css("height", "auto");
        }

}

//------------------------------------------------------------------------------

activateLevel(theEvent:any){
        const element = theEvent.srcElement;
        const elemNumber = element.textContent;
        const elemId = element.id;
        const elemBackground = $(element).css("background-color");
        let elemActive:boolean;

        if(elemBackground == "rgb(229, 83, 28)"){
            elemActive = false;
        }else{
            elemActive = true;
        }

        //...........................................................................................

        if(elemActive == true){
            $(element).css("background-color", "rgb(229, 83, 28)");

            for( var i = 0; i < this.recipeLevels_arr.length; i++){ 
                if ( this.recipeLevels_arr[i] === elemNumber) {
                    this.recipeLevels_arr.splice(i, 1); 
                }
            }
        }else{
            $(element).css("background-color", "rgb(119, 117, 233)");
            this.recipeLevels_arr.push(elemNumber);
        }

        if(this.filterCookingLevel_bool == true){
            this.filterRecipes();
        }

        if(this.recipeLevels_arr.length < 1 && this.filterCookingLevel_bool == true){
            this.recipesBackToNormal();
        }

}

//------------------------------------------------------------------------------

toggleCookingLevelFilter(theEvent:any){

        const element = theEvent.srcElement;
        this.filterCookingLevel_bool = !this.filterCookingLevel_bool

        if(element.checked == true){
            this.filterRecipes();
        }else{
            this.recipesBackToNormal();
        }
    
}

//------------------------------------------------------------------------------

filterRecipes(searchbarActivity?:any, ){

        const outConts = document.getElementsByClassName("outCont");
        let showRecipes_arr:any[]=[]; 

        for (let i = 0; i < this.recipeLevels_arr.length; i++) {
            const lvlNumber = this.recipeLevels_arr[i];

            showRecipes_arr.push(checkRecipeLvls(parseInt(lvlNumber)));
            if(i+1 == this.recipeLevels_arr.length){fixView()}
        }
        //...........................................................................................
        //THIS FUNCTION WAS MADE IN ORDER TO FILTER OUT LEVELS 

        function checkRecipeLvls(checkNumber){

        let returnRecipes_arr:any[]=[]

        for (let x = 0; x < outConts.length; x++) {
            const element = outConts[x];
            const lvlCont = element.getElementsByClassName("recipeLevel")[0];
            const recipeLvl = parseInt(lvlCont.textContent);

            if(recipeLvl == checkNumber){
                returnRecipes_arr.push(element);
            }
        }

        return returnRecipes_arr;
        }

        //...........................................................................................
        //THIS LOCAL FUNCTION FUNCTION IS THE ONE THAT ULTIMATELY HIDES/SHOWS RECIPES
       
        function fixView(){

        if(searchbarActivity){
            $(outConts).css("display", "none");

            for (let x = 0; x < showRecipes_arr.length; x++) {
                const treatedArray = showRecipes_arr[x];

                for (let i = 0; i < treatedArray.length; i++) {
                    const element = treatedArray[i];
                    let keywordsElement = element.getElementsByTagName("H6")[0];
                    let txtValue = keywordsElement.textContent || keywordsElement.innerText;

                    if (txtValue.toUpperCase().indexOf(searchbarActivity.toUpperCase()) > -1) {
                        element.style.display = "block";
                    } else {
                        element.style.display = "none";
                    } 
                }
            }

        }else{
            $(outConts).css("display", "none");

            for (let x = 0; x < showRecipes_arr.length; x++) {
                const treatedArray = showRecipes_arr[x];

                for (let i = 0; i < treatedArray.length; i++) {
                    const element = treatedArray[i];
                    $(element).css("display", "block");
                }
            }
        }

        }

}

//------------------------------------------------------------------------------

recipesBackToNormal(){
    $(".outCont").css("display", "block");
}

//------------------------------------------------------------------------------

filterMyRecipes(){

        const outConts = document.getElementsByClassName("outCont");
        let showRecipes_arr:any[]=[]; 

        //ONLY SEARCHBAR
        if(this.searchbarValue.length >= 1 && this.filterCookingLevel_bool == false && this.filterCookingTime_bool == false){

        }
        //ONLY LEVEL
        else if(this.searchbarValue.length == 0 && this.filterCookingLevel_bool == true && this.filterCookingTime_bool == false){

        }
        //ONLY TIME
        else if(this.searchbarValue.length == 0 && this.filterCookingLevel_bool == false && this.filterCookingTime_bool == true){

        }

//.............................................................................................................

        // IF EVERYTHING IS ACTIVE
        if(this.searchbarValue.length >= 1 && this.filterCookingLevel_bool == true && this.filterCookingTime_bool == true){

        }
        //IF SEARCHBAR AND LEVEL 
        else if(this.searchbarValue.length >= 1 && this.filterCookingLevel_bool == true && this.filterCookingTime_bool == false){

        }
         //IF LEVEL AND TIME 
         else if(this.searchbarValue.length == 0 && this.filterCookingLevel_bool == true && this.filterCookingTime_bool == true){

        }
         //IF SEARCHBAR AND TIME 
         else if(this.searchbarValue.length >= 1 && this.filterCookingLevel_bool == false && this.filterCookingTime_bool == true){

        }

}

//------------------------------------------------------------------------------

 filterRecipeLevel(){

        let returnArray:any[];

        for (let i = 0; i < this.recipeLevels_arr.length; i++) {
            const lvlNumber = this.recipeLevels_arr[i];
            
            returnArray.push(checkRecipeLvls(parseInt(lvlNumber)));
            if(i+1 == this.recipeLevels_arr.length){console.log(returnArray)}
        }
        //...........................................................................................

        function checkRecipeLvls(checkNumber){

            let returnRecipes_arr:any[]=[]

            for (let x = 0; x < this.outConts.length; x++) {
                const element = this.outConts[x];
                const lvlCont = element.getElementsByClassName("recipeLevel")[0];
                const recipeLvl = parseInt(lvlCont.textContent);


                if(recipeLvl == checkNumber){
                    returnRecipes_arr.push(element);
                    console.log(element)
                }
            }
            return returnRecipes_arr;

        }
    
}

//------------------------------------------------------------------------------

scrollToTop(){
    this.GlobalService.scrollTo(0,0)
}

//------------------------------------------------------------------------------

hideSidebar(){
    this.GlobalService.hideSidebar()
}

//------------------------------------------------------------------------------

imagePlacementInNameFunc( collectionClassName:string, imgPath:string, fileType:string ){

        let myElementsList = document.getElementsByClassName(collectionClassName);

        for (let i = 0; i < myElementsList.length; i++) {
            const element = myElementsList[i];
            const productFilePath = (imgPath + element.textContent + "." + fileType).replace(/ /g, '');

            $(element).css("background-image", "url("+ productFilePath +")");
        }

}

//------------------------------------------------------------------------------

continentSelectionFunc(){

    const recipeContinentIntro = document.getElementsByClassName('recipeContinent');

    for (let x = 0; x < recipeContinentIntro.length; x++) {
      const element= recipeContinentIntro[x];


if(!$(element).hasClass('recipeCreator')){
    let nameToTest = element.textContent.toLowerCase().replace(/ /g, '');

    for(let i = 0; i < this.CountryContinentService.countryContinentsArr.length; i++){
      const obj = this.CountryContinentService.countryContinentsArr[i];
      let countryToTest = obj.country.replace(/ /g, '').toLowerCase();

      if(countryToTest == nameToTest){
        element.textContent = obj.continent;
        let myContinentImg = element.previousElementSibling;
        $(myContinentImg).css("background-image", "url(../../../../imgs/location/continents/"+obj.continent.replace(/ /g, '').toLowerCase() +".png)")
      }
    }
}else{  //IF ORIGINAL RECIPE (Made by third party user)

    let myCreatorImg = element.previousElementSibling.firstChild;
    // console.log(myCreatorImg.textContent)
    if(myCreatorImg.textContent  == 'undefined'){
        $(myCreatorImg).css('background-image', 'url(../../../imgs/logos/user.png)')
    }else{
        $(myCreatorImg).css('background-image', 'url('+myCreatorImg.textContent+')')

    }

}

    
    }

}

//------------------------------------------------------------------------------

toggleCookingTimeFilter(event:any){
    console.log("Ok")
}

//------------------------------------------------------------------------------

displayUtensilsFunc(typeOfUtensils:string){

    if(typeOfUtensils == "need" || typeOfUtensils == 'needed'){

        let myDisplayItemsList =[];

        for(let i = 0; i < this.recipes.length; i++ ){
            const currentRecipe = this.recipes[i];
            
                for (let x = 0; x < currentRecipe.neededUtensils.length; x++) {
                    const utensilArray = currentRecipe.neededUtensils[x];

                    if(!myDisplayItemsList.includes(utensilArray.name)){ //IF NOT IN ARRAY< ADD IT     
                        myDisplayItemsList.push(utensilArray.name)
                        // console.log(utensilArray.name + " NUMBER:" + utensilArray.id)
                    }
                    
                }


        }


    }else if(typeOfUtensils == 'opt' || typeOfUtensils == 'optional'){


    }

}


getMyContinent(myRecipeObj){

    let recipeCountry = myRecipeObj.countryOfOrigin;

    for (let i = 0; i < this.CountryContinentService.countryContinentsArr.length; i++) {
        const countryContCombo = this.CountryContinentService.countryContinentsArr[i];
        let myRecipeCont:string;
        if(myRecipeObj.countryOfOrigin.toLowerCase() == countryContCombo.country.toLowerCase()){
                    return countryContCombo.continent.toLowerCase();
        }
    }


}

//------------------------------------------------------------------------------

fixSearchBannerBool(){
if(this.searchbarValue.length >= 1 || this.route.snapshot.params['category']){
this.searchBannerBool = true;
}
}


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS