import { RecipesService } from './../../services/recipes.service';
import { Component, OnInit } from '@angular/core';

import { CountryContinentService } from './../../services/country-continent.service';
import recipes from './../../exceptions/recipes.json';
import { count } from 'console';

@Component({
  selector: 'country-recipes',
  templateUrl: './country-recipes.component.html',
  styleUrls: ['./country-recipes.component.css']
})
export class CountryRecipesComponent implements OnInit {


  constructor(private CountryContinentService: CountryContinentService, 
                      private RecipesService:RecipesService ) { }

  myCountriesToPlayWith:any=[];

  ngOnInit() {

    this.makeCountriesArrayFunc();
  }






  makeCountriesArrayFunc(){
    
      for (let x = 0; x < this.RecipesService.theRecipes.length; x++) {
        const recipeObj = this.RecipesService.theRecipes[x];

        for(let i=0; i< this.CountryContinentService.countryContinentsArr.length; i++){
          const countryArr = this.CountryContinentService.countryContinentsArr[i];
          const country = countryArr.country;

          console.log(country)

        if(recipeObj.countryOfOrigin.toLowerCase().replace(/ /g, '') == country.toLowerCase().replace(/ /g, '') && !this.myCountriesToPlayWith.includes(country) && recipeObj.countryOfOrigin.toLowerCase().replace(/ /g, '')  !== 'caveman'){
          this.myCountriesToPlayWith.push(country);
          // console.log(this.myCountriesToPlayWith)
        }
        
        
      }
    }
  }

// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS

