import { RecipesService } from './../recipes.service';
import { GlobalService } from './../global.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RecipeCategoryGuard implements CanActivate {

  constructor(private GlobalService:GlobalService,
  private RecipesService:RecipesService,
  private myRouter:Router,
  private myRoute:ActivatedRoute ){}



canActivate(route:ActivatedRouteSnapshot, routerState:RouterStateSnapshot)
:Observable<boolean> | Promise<boolean> | boolean{

    if(this.GlobalService.isUserMobile()){
        return true
    }else{
      this.myRouter.navigate(['/recipes']);
    //   this.myRouter.navigate(['/recipes', "categories", this.RecipesService.myActiveCategoryClicked]);
    }

}


  

// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
