import { Injectable } from '@angular/core';
import munchBlogs from './../exceptions/blogs.json'

@Injectable({
  providedIn: 'root'
})
export class BlogService {



  constructor() { }

// *********************************************************************************************


  theNewBlogs:any= munchBlogs;
  theBlogs:any=[

    {"weekStartDay" : "6",
      "month" : "4",
      "title" : "The colour of food",
      "introDescription" : `Besides smell, taste, and overall appearance, 
      color is one of the most engaging details about food. If you know 
      how to make food look good, and taste good, you're all set to 
      please anyone you're aiming to cook for.`,
      "image_0Url" : "../../../../imgs/food/mexicanFood_0.jpg",
      "image_1Url" : false,
      "optionalLink" : false,
      "optionalRouterLink" : '/recipes',
      "optionalLinkText" : "Check out some recipes!",
      "beforeSubTexts" : [

       { "text" : `Presentation is a very important detail when it comes 
       to satisfying the client, Take McDonald's logo as a clear example. It's 
       been prooven that the color yellow is associated with happiness and is 
       the most visible color in daylight, so that's why the McDonald's logo 
       is so easy to spot on a crowded road. The brain processes color 
       before it processes words or shapes, so that's why the fast-food 
       chain chose these two colors for their logo and brand.`},


       { "text" : `Additionally, there's much more that comes associated to the 
       color like restaurants that make their logos with, and the way they decorate their 
       multiple locations. You see as it Lisa from awgsalesservices states, 
        "Yellow and orange are colors that make people feel 
       hungry. The color red is associated with emotion and passion. So when one
        sees red combined with yellow and orange, they become passionately hungry. 
        Green and earthy tones for usually used for eco-friendliness, natural, organic, 
        healthy food choices." This tells us that color will always be a huge factor 
        when aiming to please whoever one is cooking for.`}


    
    ],
      "subTitle" : false,
      "afterSubTexts": false
      
  },


  {"weekStartDay" : "13",
  "month" : "4",
  "title" : "KOKE",
  "introDescription" : `You know, this text has to be a short description for what's coming to be 
  right after the picture. Aside from this, make sure that it doesn't exceed 
   a certain length...`,
  "image_0Url" : "../../../../imgs/food/mushrooms.jpg",
  "image_0Alt" : "",
  "image_1Url" : false,
  "image_1Alt" : false,
  "optionalLink" : "http://www.onemunch.com",
  "optionalLinkText" : "Click Here!",
  "beforeSubTexts" : [

   { "text" : `Describing whatever it is that I want to describe here is important! Gotta 
    be fully concerned about the topic, about the point we're trying to get 
    across. Not only that, we have to make sure to give the client something to 
    come back for. Something to keep them thirsty!`}


],
  "subTitle" : "What else to consider?",
  "afterSubTexts": [

    {"text": `Describing whatever it is that I want to describe here is important! Gotta 
    be fully concerned about the topic, about the point we're trying to get 
    across. Not only that, we have to make sure to give the client something to 
    come back for. Something to keep them thirsty!`},

    {"text": `Describing whatever it is that I want to describe here is important! Gotta 
    be fully concerned about the topic, about the point we're trying to get 
    across. Not only that, we have to make sure to give the client something to 
    come back for. Something to keep them thirsty!`},

  ]
}

  ];

// *********************************************************************************************
//GLOBAL FUNCTIONS

  

chooseMyWeeklyBlog(){
// return('1')
}



// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS


