import { IngredientsService } from '../../../../../services/ingredients.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-ing-irreg-meas-opt',
  templateUrl: './ing-irreg-meas-opt.component.html',
  styleUrls: ['./ing-irreg-meas-opt.component.css', './../../../../ingredients/ingredients.component.css']
})
export class IngIrregMeasComponentOpt implements OnInit, OnChanges {

  constructor(private IngredientsService:IngredientsService) { }

  @Input("thePassedIngs") myPassedIngs:any;
  @Input("servingsAmount") peopleAmount:any;

  irregMeasurements:any=this.IngredientsService.myIrregIngMeasurements;


  ngOnInit() {
  } 

  ngOnChanges(){
  }

}
