import { element } from 'protractor';
import { GlobalService } from './../../services/global.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', './responsive-header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit{

  constructor(private GlobalService:GlobalService) { }

// *********************************************************************************************
// STATIC VARIABLES

// *********************************************************************************************
//LIFECYCLE HOOKS


ngOnInit() {
    // console.log("Screen width is " +this.GlobalService.wWidth);
    // console.log("mobileUser is " + this.GlobalService.mobileUser);

    if(!this.GlobalService.isUserMobile()){

      $("#headerLogo").on("mouseover", ()=>{
        $("#headerLogo").css("background-image", "url(../../../imgs/header.comp/headerLogoMove.jpg)")
      });

      $("#headerLogo").on("mouseout", ()=>{
        $("#headerLogo").css("background-image", "url(../../../imgs/header.comp/headerLogo.jpg)")
      });

      $("#navBarC_0").on("mouseover", ()=>{
        $("#navBarL_0").css("background-image", "url(../../../imgs/header.comp/navbar/foodtionaryMove.png)")
      });

      $("#navBarC_0").on("mouseout", ()=>{
        $("#navBarL_0").css("background-image", "url(../../../imgs/header.comp/navbar/foodtionary.png)")
      });


      $("#navBarC_2").on("mouseover", ()=>{
        $("#navBarL_2").css("background-image", "url(../../../imgs/header.comp/navbar/weeklyBlogMove.png)")
      });

      $("#navBarC_2").on("mouseout", ()=>{
        $("#navBarL_2").css("background-image", "url(../../../imgs/header.comp/navbar/weeklyBlog.png)")
      });


      

      

    }
}

//------------------------------------------------------------------------------

ngAfterViewInit(){
    let headerChoices = document.getElementById('munchNavHeaderUl').children;

    for (let i = 0; i < headerChoices.length; i++) {
      const element = headerChoices[i];
      if(this.GlobalService.wWidth <= 1030){
        $(element).click(()=>{
          this.onThreeBarClick()
        })

        this.GlobalService.toggleSidebar();
      }

     

    }
}

// *********************************************************************************************
//STATIC FUNCTIONS

onThreeBarClick(){
    this.GlobalService.toggleSidebar();
}

//------------------------------------------------------------------------------

logTheWidth(){
    // console.log(this.GlobalService.wWidth)
}

//------------------------------------------------------------------------------

logTheMobileBool(){
    console.log(  this.GlobalService.mobileUser);
}

//------------------------------------------------------------------------------

closeSidebar(){
    // console.log(this.GlobalService.dropNavOpen)
    if(this.GlobalService.dropNavOpen){
      $("#munchNavHeaderUlCont").slideUp(500);
      this.GlobalService.dropNavOpen = false;
    }
}







// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
