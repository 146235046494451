import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'pizza-builder-simple',
  templateUrl: './pizza-builder-simple.component.html',
  styleUrls: ['./pizza-builder-simple.component.css', './responsive-pizza-builder-simple.component.css']
})


export class PizzaBuilderSimpleComponent implements OnInit{

  constructor() {

  }

// *********************************************************************************************
  // STATIC VARIABLES

  mobileActive:boolean=false;
    activeMobileSection:string;
    restMobileSection_1:string;
    restMobileSection_0:string;
    toppingsActive:boolean=false;
    sauceActive:boolean=false;
    cheeseActive:boolean=false;
    activeToppings:string[]= [];
    activeSauce:string="";
    activeCheese:string="";
    amountOfToppings:number;
    toppingDecorNum:number=1;
    pizzaCheeses_s:string[]=[];

    pizzaCheeses:string[]=[
      "mozzarella",
      "provolone",
      "cheddar",
      "parmesan"
    ];

  
    pizzaSauces:string[]= [
      "marinara", 
      "alfredo",
      "benchamel",
      "pesto"
    ];

    pizzaToppings:string[]= [
      "pepperonni",
      "mushroom", 
      "pineapple",
      "bacon"
    ];

// *********************************************************************************************

ngOnInit() {

    var self = this;
    let wWidthS = window.innerWidth;

//..........................................................................................

  $(window).resize(function(){
    let wWidth = window.innerWidth;
    sizeFix(wWidth)
  });

//..........................................................................................

  function sizeFix(w:number){
    if(w <= 530){
      onMobileScreen(w)
    }else{
      onBigScreen(w)
    }
  }

//..........................................................................................

  function onMobileScreen(w:number){
    self.mobileActive = true;
    self.activeMobileSection = "toppings"
    self.restMobileSection_0 = "sauce"
    self.restMobileSection_1 = "cheese"

    self.toppingsActive = true;
    self.sauceActive = false;
    self.cheeseActive = false;
  }

//..........................................................................................

  function onBigScreen(w:number){
    self.mobileActive = false;

    self.toppingsActive = true;
    self.sauceActive = true;
    self.cheeseActive = true;
  }

//..........................................................................................

  for (let sauce of this.pizzaCheeses) {
    this.pizzaCheeses_s.push(sauce.replace(/ /g, ''));
    // console.log(this.pizzaCheeses_s)
  }

 //..........................................................................................

  sizeFix(wWidthS)

} 



// *********************************************************************************************
//STATIC FUNCTIONS

onToppingClick(event:any){
    let toppingName= (event.target.innerText).toLowerCase();
    let boolName = toppingName + "Active";


    if(this[boolName] ){
      this[boolName]  = false;
      let itemPos = this.activeToppings.indexOf(toppingName);

      this.activeToppings.splice(itemPos, 1);
      console.log(this.activeToppings)
    }else{
      this[boolName] = true
      this.activeToppings.push(toppingName)
    }

    this.onPizzaChange()

}


// -----------------------------------------------------------------------------------------------------

onSauceClick(event:any){

    let sauceName= (event.target.innerText).toLowerCase();
    let boolName = sauceName + "Active";

    if(this[boolName] ){
        this[boolName]  = false;
        this.activeSauce = "";
    }else{
        this[boolName] = true;
        if(this.activeSauce == ""){
          this.activeSauce = sauceName
        }else{
          for (let sauce of this.pizzaSauces) {
            let thisSauceBool = sauce + "Active";

            if(thisSauceBool == boolName){
              this.activeSauce = sauce
              this[thisSauceBool] = true;
            }else{
              this[thisSauceBool] = false;
            }
          }
        }
    } 

}

  // -----------------------------------------------------------------------------------------------------

  onCheeseClick(event:any){

    let cheeseName= (event.target.innerText).toLowerCase().replace(/ /g, '');
    let boolName = cheeseName + "Active";

    if(this[boolName] ){
      this[boolName]  = false;
      this.activeCheese = "";
    }else{
      this[boolName] = true;
      if(this.activeCheese == ""){
        this.activeCheese = cheeseName
      }else{
        for (let cheese of this.pizzaCheeses) {
          let myCheese = cheese.toLowerCase().replace(/ /g, '');
          let thisCheeseBool = myCheese + "Active";

          if(thisCheeseBool == boolName){
            this.activeCheese = myCheese
            this[thisCheeseBool] = true;
          }else{
            this[thisCheeseBool] = false;
          }
        }
      }
    } 

  }
  
// -----------------------------------------------------------------------------------------------------

onPizzaChange(){

    this.amountOfToppings = this.activeToppings.length;

    if(this.amountOfToppings >= 4){
      this.toppingDecorNum = 3;
    }else if(this.amountOfToppings == 3){
      this.toppingDecorNum = 2;
    }else if(this.amountOfToppings == 2){
      this.toppingDecorNum = 1;
    }

}

// -----------------------------------------------------------------------------------------------------

    cookPizza(event:any){

    let sauceBool:boolean=false;
    let cheeseBool:boolean=false;
    let toppingsBool:boolean=false;

    for (let index = 0; index < this.pizzaSauces.length; index++) {
      const element = this.pizzaSauces[index];
      const elementBool =  element + "Active";

      if(this[elementBool] == true){
        sauceBool = true
      }
    }


    for (let index = 0; index < this.pizzaCheeses.length; index++) {
      const element = this.pizzaCheeses[index];
      const elementBool =  element + "Active";

      if(this[elementBool] == true){
        cheeseBool = true
      }
    }


    for (let index = 0; index < this.pizzaToppings.length; index++) {
      const element = this.pizzaToppings[index];
      const elementBool =  element + "Active";

      if(this[elementBool] == true){
        toppingsBool = true
      }
    }


    if(sauceBool === false || cheeseBool === false || toppingsBool === false){
      this.errorPizza(event);
    }else{
      if($("#errorMessage").is(":visible")){
        $("#errorMessage").fadeOut(900);
      }
    }

}

// -----------------------------------------------------------------------------------------------------

  errorPizza(event:any){

      let thisEvent = event.srcElement;
      $(thisEvent).addClass("shake");
      $("#errorMessage").fadeIn(900)

      setTimeout(function(){
        $(thisEvent).removeClass("shake");
      }, 500);
      
  }

// -----------------------------------------------------------------------------------------------------

  toggleDropdown(){
    $(".dropdown-content").toggle();
  }

// -----------------------------------------------------------------------------------------------------

mobileActiveChange(newSecElem:any, num:number){

    let newSec = newSecElem.srcElement.textContent;
    let lastSec = this.activeMobileSection;
    let sectionToModify = "restMobileSection_" + num;
    let secToHide = lastSec+"Active";
    let secToShow = newSec+"Active"

    this[secToHide] = false;
    this[secToShow] = true;

    this.activeMobileSection = newSec;
    this[sectionToModify] = lastSec;

    $(".dropdown-content").slideUp(300);

}


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS


