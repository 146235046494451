import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start-questionare',
  templateUrl: './start-questionare.component.html',
  styleUrls: ['./start-questionare.component.css']
})
export class StartQuestionareComponent implements OnInit {

  constructor() { }

// *********************************************************************************************
//STATIC VARIABLES


// *********************************************************************************************
// LIFECYCLE HOOKS

ngOnInit() {
}

// *********************************************************************************************
//STATIC FUNCTIONS





// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
