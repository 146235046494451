import { Continent } from './../../models/continent.model';
import { GlobalService } from './../../services/global.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'welcome-to-munch',
  templateUrl: './welcome-to-munch.component.html',
  styleUrls: ['./welcome-to-munch.component.css', './responsive-welcome-to-munch.component.css']
})

export class WelcomeToMunchComponent implements OnInit, AfterViewInit {

  constructor(private GlobalService:GlobalService) { }

// *********************************************************************************************

    timeOfDay:string;
    currentQuestion:number;
    questionnaireComplete:boolean;
    amountOfQuestions:number;
    mainContinents: Continent[]=[
    new Continent('asia', 'as'),
    new Continent('europe', 'eu'),
    new Continent('africa', 'af'),
    new Continent('south america', 'sa'),
    new Continent('north america', 'na'),
    new Continent('antartica', 'an'),
    new Continent('australia', 'au')
   ];

// *********************************************************************************************

ngOnInit() {
  this.GlobalService.checkTime();
  this.timeOfDay = this.GlobalService.timeOfDay;
}
  
// -----------------------------------------------------------------------------------------------------

ngAfterViewInit(){
    let contImgs = document.getElementsByClassName('question_2Img');

    for (let i = 0; i < contImgs.length; i++) {
        const element = contImgs[i];
        const theId = element.id;
        $(element).css('background-image', 'url(../../../imgs/body/continents/' + theId.replace(/ /g, '').toLowerCase()+'_W.png')
    }

    const questionsCont = document.getElementById('userQuestionnaire');
    this.amountOfQuestions = questionsCont.children.length -1;
}

// *********************************************************************************************

startMyQuestionnaire(){

    let politeUserQuestion = document.getElementById('newUserContDesc');
    let myQuestionnaire = document.getElementById('userQuestionnaire');

    $(politeUserQuestion).slideUp(800);
    $(myQuestionnaire).delay(799).slideDown(500);
    this.currentQuestion = 0;

}

// -----------------------------------------------------------------------------------------------------

answeredQuestion(questionNumber:number, myEvent:Event){

    if(this.amountOfQuestions !== questionNumber){
      this.currentQuestion = questionNumber+1;
    }

    const myElement:any= myEvent.srcElement;
    const myId = myElement.id;
    const grandparentId = myElement.parentElement.parentElement.id;
    const mySiblings = myElement.parentElement.children;

    for (let i = 0; i < mySiblings.length; i++) {
      const element = mySiblings[i];
      $(element).css("background-color", "transparent");
    }

    localStorage.setItem(grandparentId, myId);
    $(myElement).css("background-color", "#266706")
    this.passQuestion(questionNumber);

}

// -----------------------------------------------------------------------------------------------------

passQuestion(questionNumber:number){

    const questionsCont = document.getElementById('userQuestionnaire');
    const amountOfQuestions = questionsCont.children.length;

    if(questionNumber !== amountOfQuestions - 2){
        const currentQuestion = document.getElementById("question_" + questionNumber);
        const nextQuestion = currentQuestion.nextElementSibling;

        $(currentQuestion).slideUp(500);
        $(nextQuestion).slideDown(1000);
    }else{
        const mySubmitButton= document.getElementById('submitFormBtn');
        $(mySubmitButton).fadeIn(700);
    }

}

// -----------------------------------------------------------------------------------------------------

backQuestion(currentQuestionNumber:number){

    const currentQuestion = document.getElementById("question_" + currentQuestionNumber);
    const previousQuestion = currentQuestion.previousElementSibling;

    $(currentQuestion).slideUp(500);
    $(previousQuestion).slideDown(1000);

    this.currentQuestion = this.currentQuestion-1;

}

// -----------------------------------------------------------------------------------------------------

onQuestionnaireComplete(){

    localStorage.setItem("questionnaireComplete", 'true');
    this.GlobalService.questionnaireComplete = true;
    this.questionnaireComplete = true;

    const thankYouMessage = document.getElementById("thankYouMessageCont");
    const newUserCont = document.getElementById("newUserCont");

    $(thankYouMessage).slideDown(600);
    $(newUserCont).delay(4500).slideUp(700)
    // window.scrollTo(0, 0);
    
}




// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
