import { GlobalService } from './../global.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router, Params } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileRecipeGuard implements CanActivate {

constructor(private GlobalService:GlobalService,
private myRouter:Router,
private myRoute:ActivatedRoute){}

canActivate(route:ActivatedRouteSnapshot, routerState:RouterStateSnapshot)
:Observable<boolean> | Promise<boolean> | boolean{

    if(this.GlobalService.isUserMobile()){
      this.myRouter.navigate(['/mobileRecipes']);
    }else{
      return true
    }

}
  


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS