import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-weekly-blog',
  templateUrl: './weekly-blog.component.html',
  styleUrls: ['./weekly-blog.component.css']
})

export class WeeklyBlogComponent implements OnInit {

  constructor() { }

// *********************************************************************************************


// *********************************************************************************************

ngOnInit() {
}







// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
