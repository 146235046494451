import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'

@Component({
  selector: 'experimental',
  templateUrl: './experimental.component.html',
  styleUrls: ['./experimental.component.css']
})
export class ExperimentalComponent implements OnInit {

  constructor() { }

// *********************************************************************************************

ngOnInit() {
  // $('.recipeGrayCont').scrollLeft();
  const recipeNameCont = document.getElementById('recipeNameCont');
  recipeNameCont.scrollIntoView();
}






// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
