import { RecipesService } from './../../../../../services/recipes.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { IngredientsService } from './../../../../../services/ingredients.service';
import { AfterContentInit, Component, Input, OnChanges, OnInit, DoCheck, AfterViewInit, AfterContentChecked } from '@angular/core';
import * as $ from 'jquery'

@Component({
  selector: 'app-ing-item-meas',
  templateUrl: './ing-item-meas.component.html',
  styleUrls: ['./ing-item-meas.component.css', './../../../../ingredients/ingredients.component.css']
})
export class IngItemMeasComponent implements OnInit, AfterContentInit, DoCheck , AfterViewInit, OnChanges, AfterContentChecked{

  @Input("thePassedIngs") myPassedIngs:any;
  @Input("servingsAmount") peopleAmount:any;
  afterStart:boolean=false;
  myServingsMultiplier:any; 
  myRecipeId:any = undefined;
  recipeObj:any=[];
  
//------------------------------------------------------------------------------

  constructor(private IngredientsService:IngredientsService,
                      private ActivatedRoute: ActivatedRoute,
                      private RecipesService: RecipesService) { }

//------------------------------------------------------------------------------
//Called before ngOnInit() and whenever one or more data-bound input properties change.
//Note that if your component has no inputs or you use it without providing any @Input(s), the framework will not call ngOnChanges().

ngOnChanges(){ 

    if(this.afterStart){
      this.IngredientsService.onPplInputChange("ingredientMeasurementPBlock", parseInt(this.peopleAmount) / this.recipeObj.pplPerRecipe, this.myPassedIngs)
      // console.log(parseInt(this.peopleAmount) +" VS "+ this.recipeObj.pplPerRecipe )

    }
  
}

//------------------------------------------------------------------------------
// Called once, after the first ngOnChanges().

  ngOnInit() {
    //Variable setting
  if(this.ActivatedRoute.snapshot.params['recipeId'])   {
    this.myRecipeId = this.ActivatedRoute.snapshot.params['recipeId'];
    this.recipeObj = this.RecipesService.theRecipes[this.myRecipeId - 1];
  }

  this.myServingsMultiplier =  parseInt(this.peopleAmount) / this.recipeObj.pplPerRecipe; 

  }

//------------------------------------------------------------------------------
//Called immediately after ngOnChanges() on every change detection run, and immediately after ngOnInit() on the first run.

ngDoCheck(){


 
  
}

//------------------------------------------------------------------------------
//Called once after the first ngDoCheck().

ngAfterContentInit(){

}

//------------------------------------------------------------------------------
//Called after ngAfterContentInit() and every subsequent ngDoCheck().
ngAfterContentChecked(){


}

//------------------------------------------------------------------------------
//Called once after the first ngAfterContentChecked().

  ngAfterViewInit(){

    this.IngredientsService.fractionMyIngredientMeasurements("ingredientMeasurementPBlock");
    this.afterStart = true;
    

  }

// *********************************************************************************************
//LOCAL FUNCTIONS




// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS

