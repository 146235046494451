import { CategoriesService } from './../../../services/categories.service';
import { GlobalService } from './../../../services/global.service';
import { CountryContinentService } from './../../../services/country-continent.service';
import { RecipesService } from './../../../services/recipes.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit, AfterViewInit, DoCheck } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-recipe',
  templateUrl: './mobile-recipe.component.html',
  styleUrls: ['./mobile-recipe.component.css', './responsive-mobile-recipe.component.css']
}) 

export class MobileRecipeComponent implements OnInit, AfterViewInit, DoCheck {

  constructor( private route:ActivatedRoute,
    private router:Router,
    private RecipesService:RecipesService,
    private CountryContinentService:CountryContinentService,
    private GlobalService:GlobalService,
    private CategoriesService:CategoriesService) { }


// *********************************************************************************************

    myRecipeId:number;
    myRecipeObj:any;
    infoActiveVar:string='ingredients';
    myRecipeContinent:string;
    visibleRecipe:boolean=true;
    amountOfPeople:number= 1;
    currentCookQuestion:number=1;
    recipeCookingType:string = "simplerecipe" ;
    cookingTypeMessage:any;
    
// *********************************************************************************************

ngOnInit() {

if(this.route.snapshot.params['recipeId'])   {
    this.myRecipeId = this.route.snapshot.params['recipeId'];
    this.myRecipeObj = this.RecipesService.theRecipes[this.myRecipeId - 1]

    for (let i = 0; i < this.CountryContinentService.countryContinentsArr.length; i++) {
      const obj = this.CountryContinentService.countryContinentsArr[i];
      let nameToTest = obj.country.replace(/ /g, '').toLowerCase();

      if(nameToTest == this.myRecipeObj.countryOfOrigin.replace(/ /g, '').toLowerCase() ){
        this.myRecipeContinent = obj.continent;
      }
    }

    this.route.params.subscribe((theParams: Params)=>{
      this.myRecipeId = theParams['recipeId'];
    })
}

}

// ----------------------------------------------------------------------------------------

ngAfterViewInit(){

this.fixImages('mainRecipeName', 'text',  '../../../imgs/food/startRecipes/', 'jpg');
this.fixImages('recipeIngredientOutCont', 'id', '../../../imgs/food/ingredients/', 'jpg');
this.fixImages('countryFlag', 'text', '../../../imgs/flags/', 'png');
this.fixImages('continentImg', 'text', '../../../imgs/body/continents/', 'png')
this.fixImages('nutritionFactImg', 'text', '../../../imgs/food/nutrition/recipes/', 'png')
this.GlobalService.scrollTop();

}

// ----------------------------------------------------------------------------------------

ngDoCheck() {
  this.checkMyInfoDrop();
}

// *********************************************************************************************

checkMyInfoDrop(){

  const myConts = document.getElementById('categoryInfoCont').children;

  for (let i = 0; i < myConts.length; i++) {
      const element = myConts[i];
      let myId = element.id;

      if(this.infoActiveVar == 'ingredients' && myId == 'ingredientsCont_n'){
        $(element).show();
      }else if(this.infoActiveVar == 'aboutRecipe' && myId == 'aboutRecipeCont'){
        $(element).show();
      } else if(this.infoActiveVar == 'nutritionFacts' && myId == 'nutritionFactsCont'){
        $(element).show();
      }else{
        $(element).hide();
      }
  }

}

  // ----------------------------------------------------------------------------------------

fixImages(arrayOfContsClass:string, typeOfIntegration:string, folderPath:string, imgFormat:string){
      
    let myArrayOfConts = document.getElementsByClassName(arrayOfContsClass);

    for (let i = 0; i < myArrayOfConts.length; i++) {
        const element = myArrayOfConts[i];
        let myElemIntegration;

        if(typeOfIntegration == 'id'){
          myElemIntegration = element.id;
        }else if(typeOfIntegration == 'text'){
          myElemIntegration = element.textContent;
        }
      
        const myImgName = myElemIntegration.replace(/ /g, '').toLowerCase();
        $(element).css('background-image', 'url('+ folderPath +myImgName+'.'+imgFormat+')');
    }

}

  // ----------------------------------------------------------------------------------------

toggleRecipeName(){

    let myPictureCont = document.getElementById('mainRecipeCont');
    let myRecipeName = document.getElementById('myRecipeMainName');
    let infoCont = document.getElementById("infoCont")

    if(this.visibleRecipe){
      $(myRecipeName).fadeOut(500);
      $(infoCont).css('background', 'linear-gradient(  rgba(255, 255, 255, .01), rgba(255, 255, 255, .9))');
    }else{
      $(myRecipeName).fadeIn(500);
      $(infoCont).css('background', 'linear-gradient(  rgba(255, 255, 255, .3),#FFF)');
    }

    this.visibleRecipe = !this.visibleRecipe;

}

  // ----------------------------------------------------------------------------------------

startCookingRecipe(){

    if(window.innerHeight > window.innerWidth){
      $('#mainRecipeCont').slideUp(1000);
      $('#quickInfoCont').slideUp(1000);
      $('#startingToCook').slideDown(1200);
      $('#cookQuestion_1').slideDown(600)
    }

}

  // ----------------------------------------------------------------------------------------

changeAmtPpl(integerBool:boolean, inputEvent?:Event){

    if(integerBool){
      this.amountOfPeople++;
    }else if(inputEvent ){
      let inputElement:any= inputEvent.srcElement;
      this.amountOfPeople = inputElement.value;
    }else if(this.amountOfPeople >= 2 ){
      this.amountOfPeople--;
    }

}

// ----------------------------------------------------------------------------------------

changeQuestion(direction:string){

    let nextQuestionNum = this.currentCookQuestion+1;
    let prevQuestionNum = this.currentCookQuestion-1;
    let currentQuestionElem = document.getElementById('cookQuestion_' + this.currentCookQuestion);
    let nextQuestionElem = document.getElementById('cookQuestion_' + nextQuestionNum);
    let previousQuestionElem = document.getElementById('cookQuestion_' + prevQuestionNum)

    if(direction == 'next'){

    if(this.currentCookQuestion == 1 && this.amountOfPeople == 0){
      $("#amtPplErrorCont").text("ERROR! Make sure to choose a valid amount of people.");
      $("#amtPplErrorCont").slideDown(800);
    }else  if(this.currentCookQuestion == 1 && this.amountOfPeople  == NaN){
      console.log("NOT A NUMBER!")
    }
    else if(this.currentCookQuestion == 2){
      this.letsCook();
    }else{
      $(currentQuestionElem).slideUp(500);
      $(nextQuestionElem).slideDown(800);
      this.currentCookQuestion = nextQuestionNum;
      $("#amtPplErrorCont").slideUp(1200);
    }

    }else if(direction == 'prev'){
      $(currentQuestionElem).slideUp(500);
      $(previousQuestionElem).slideDown(800);
      this.currentCookQuestion = prevQuestionNum;
    }else{
      console.error('Error 301! Incorrect argument passed to function.')
    }

}

// ----------------------------------------------------------------------------------------

 chooseCookingType(myEvent:any){

    let myElement = myEvent.srcElement;
    let myRecipeType = myElement.textContent;
    let myParentCont = myElement.parentElement;

    $('#cookingTypeMessageT').css('display', "block");
    $("#cookingTypeMessageT").removeClass('errorMessage');
    $(myElement).addClass('activeTypeSelection');
    this.recipeCookingType = myRecipeType.replace(/ /g, '').trim();


    for (let i = 0; i < myParentCont.children.length; i++) {
      const element = myParentCont.children[i];
      $(element).removeClass('activeTypeSelection')
    }

    for (let i = 0; i < this.CategoriesService.RecipeCategories.length; i++) {
      const element = this.CategoriesService.RecipeCategories[i];

      if(element.name.replace(/ /g, '').toLowerCase().trim() == this.recipeCookingType.toLowerCase()){
        this.cookingTypeMessage = element.description;
      }
    }

}

// ----------------------------------------------------------------------------------------

letsCook(){

    if(this.recipeCookingType == undefined){//IF NO TYPE HAS BEEN CHOSEN
      $('#cookingTypeMessageT').css('display', "block");
      $("#cookingTypeMessageT").addClass('errorMessage');
      this.cookingTypeMessage = 'Please choose how you would like to view the recipe before continuing.';
    }else{
      $('#startingToCook').slideUp(850)
      $('#letsCook').slideDown(1000)
    }

}


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS


