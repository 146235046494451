import { Title } from '@angular/platform-browser';
import { GlobalService } from './../../services/global.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private GlobalService:GlobalService, 
                      private TitleService: Title) { }

// *********************************************************************************************
//STATIC VARIBLES

questionnaireComplete:boolean;

// *********************************************************************************************
//LIFECYCLE HOOKS

ngOnInit() {
    this.TitleService.setTitle("MunCH!  · Home")
    this.questionnaireComplete = this.GlobalService.isQuestionnaireComplete();
}

// *********************************************************************************************
//STATIC FUNCTIONS



  



// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
