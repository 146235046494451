import { Component, OnInit, AfterViewInit, ViewChild, DoCheck, AfterContentChecked, AfterViewChecked, HostListener } from '@angular/core';
import {  ActivatedRoute, Router, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery'

import { RecipesService } from './../../../services/recipes.service';
import { GlobalService } from './../../../services/global.service';
import { CountryContinentService } from './../../../services/country-continent.service';

@Component({
  selector: 'app-mobile-recipes',
  templateUrl: './mobile-recipes.component.html',
  styleUrls: ['./mobile-recipes.component.css', './responsive-mobile-recipes.component.css']
})

export class MobileRecipesComponent implements OnInit, AfterViewInit, DoCheck {

constructor(private GlobalService: GlobalService,
                    private RecipesService:RecipesService,
                    private CountryContinentService: CountryContinentService,
                    private route:ActivatedRoute,
                    private Router:Router) { }

// *********************************************************************************************
//DECORATORS

@ViewChild('timeFilter', { static: true }) timeFilterCont: NgForm;
@HostListener('window:resize', ['$event'])
onResize(event) {
// this.recipeNameView();
}


// *********************************************************************************************
//STATIC VARIABLES


  math =Math;
  searchbarValue:string="";
  sidebarVisible:boolean;
  recipeLevels_arr:any=[];
  recipes = this.RecipesService.getRandomRecipes();
  minValTimeFilter:number=10;
  maxValTimeFilter:number=15;
  timeFilterCheck:boolean=false;
  filterIsOnBoolean:boolean=false;
  timeFilterIsOnboolean=false;
  noResultsBool=false;
  myShowArray:any=[];
  recipesCategory:string;



// *********************************************************************************************

ngOnInit() {

  this.GlobalService.isUserMobile();
  this.checkIfCategory();



}

//------------------------------------------------------------------------------
 
ngAfterViewInit(){

    this.recipeNameView();
    this.imagePlacementRecipe('recipeSlideCont');
    this.imagePlacementInNameFunc('countryImg', '../../../../imgs/location/countries/', 'png');
    this.imagePlacementInNameFunc('plainImageCont_r', '../../../../imgs/food/startRecipes/', 'jpg');
    this.continentSelectionFunc();

    if(this.route.snapshot.params['category']){
      this.onSearchbarKeyup("myEvent", document.getElementById('theSearchbar'));
    }

    setTimeout(() => {
          this.GlobalService.scrollTop();
    }, 300);

    setTimeout(() => {
      this.ifTutorialNeededFunc()
    }, 800);

}

//------------------------------------------------------------------------------

ngDoCheck(){
    this.filterIsOnFunc();
}

// *********************************************************************************************

sidebarToggle(optionalClose?:boolean){

    let sidebar = $("#sidebar");
    let mainRecipesCont = $("#mainRecipesCont")
    console.log("Screen size is " + this.GlobalService.retrieveScreenWidth());

    if(this.sidebarVisible || optionalClose){ //If sidebar's open
      $(mainRecipesCont).children().removeClass("pointerEventsNone");
      $(mainRecipesCont).off("click");
      $(sidebar).removeClass('sidebar_so');
    }else{
      $(sidebar).addClass('sidebar_so');
      $(mainRecipesCont).children().addClass("pointerEventsNone");

      window.setTimeout(() => {
        $(mainRecipesCont).click((e)=>{
        this.sidebarToggle()
        $(sidebar).removeClass('sidebar_so');
        e.stopPropagation(); //Prevent any other functions from triggering
        e.preventDefault();  //Prevent default activities
        })
      }, 500);
    }

    this.sidebarVisible = !this.sidebarVisible;

}

//------------------------------------------------------------------------------

imagePlacementRecipe(theClass:any){

    let myArray = document.getElementsByClassName(theClass)

    for (let i = 0; i < myArray.length; i++) {
      const element = myArray[i];
      const recipeHeader = element.getElementsByClassName('recipeName')[0];
      const recipeName = recipeHeader.textContent.replace(/ /g, '').toLowerCase();
      $(element).css("background-image", "url(../../../../imgs/food/startRecipes/"+recipeName+"_1.jpg)");
    }

}

//------------------------------------------------------------------------------

recipeNameView(exception?:any){

    let recipeNameConts:any= document.getElementsByClassName('recipeNameCont');

    for (let i = 0; i < recipeNameConts.length; i++) {
      const element = recipeNameConts[i];
      element.scrollIntoView();
    }

}

//------------------------------------------------------------------------------

imagePlacementInNameFunc( collectionClassName:string, imgPath:string, fileType:string ){

    let myElementsList = document.getElementsByClassName(collectionClassName);

    for (let i = 0; i < myElementsList.length; i++) {
      const element = myElementsList[i];
      const productFilePath = (imgPath + element.textContent + "." + fileType).replace(/ /g, '');

      $(element).css("background-image", "url("+ productFilePath +")");
    }

}

//------------------------------------------------------------------------------

continentSelectionFunc(){

  const recipeContinentIntro = document.getElementsByClassName('recipeContinent');

  for (let x = 0; x < recipeContinentIntro.length; x++) {
    const element= recipeContinentIntro[x];


if(!$(element).hasClass('recipeCreator')){
  let nameToTest = element.textContent.toLowerCase().replace(/ /g, '');

  for(let i = 0; i < this.CountryContinentService.countryContinentsArr.length; i++){
    const obj = this.CountryContinentService.countryContinentsArr[i];
    let countryToTest = obj.country.replace(/ /g, '').toLowerCase();

    if(countryToTest == nameToTest){
      element.textContent = obj.continent;
      let myContinentImg = element.previousElementSibling;
      $(myContinentImg).css("background-image", "url(../../../../../imgs/location/continents/"+obj.continent.replace(/ /g, '').toLowerCase() +".png)")
    }
  }
}else{  //IF ORIGINAL RECIPE (Made by third party user)

  let myCreatorImg = element.previousElementSibling.firstChild;
  // console.log(myCreatorImg.textContent)
  if(myCreatorImg.textContent  == 'undefined'){
      $(myCreatorImg).css('background-image', 'url(../../../../imgs/logos/user.png)')
  }else{
      $(myCreatorImg).css('background-image', 'url('+myCreatorImg.textContent+')')

  }

}
  
  }

}


//------------------------------------------------------------------------------

activateLevel(myEvent?:Event){

    $("#theSearchbar").val("");

    const htmlElementHandled:any=myEvent.srcElement;
    const levelHandled:any=parseInt(htmlElementHandled.id);
    const elemBackground = $(htmlElementHandled).css("background-color");
    let elemActive:boolean;


    if(elemBackground == "rgb(229, 83, 28)"){
      elemActive = false;
    }else{
      elemActive = true;
    }

    // ...........................................................................................

    if(elemActive == true){
      $(htmlElementHandled).css("background-color", "rgb(229, 83, 28)");

      for( var i = 0; i < this.recipeLevels_arr.length; i++){
        if ( this.recipeLevels_arr[i] === levelHandled) {
            this.recipeLevels_arr.splice(i, 1);
        }
      }
    }else{
      $(htmlElementHandled).css("background-color", "rgb(119, 117, 233)");
      this.recipeLevels_arr.push(levelHandled);
    }

    if(this.recipeLevels_arr.length == 0){
      this.recipesBackToNormal();
    }

    if($('#cookingTimeCheckbox').prop('checked')){ //If time checkbox is checked
      this.timeFilterFunc(); // Run timeFilterFunc
    }else{
      this.filterRecipes(); //Simply run filterRecipes Function
    }

}

//------------------------------------------------------------------------------

filterRecipes(searchbarActivity?:any, ){

    this.noResultsBool = false;

    const outConts = document.getElementsByClassName("recipeOutCont");
    let showRecipes_arr:any[]=[];

    for (let i = 0; i < this.recipeLevels_arr.length; i++) {
      const lvlNumber = this.recipeLevels_arr[i];

      showRecipes_arr.push(checkRecipeLvls(parseInt(lvlNumber)));
      if(i+1 == this.recipeLevels_arr.length){fixView()}
    }



    //------------------------------------------------------------------------------
    //THIS FUNCTION WAS MADE IN ORDER TO FILTER OUT LEVELS

    function checkRecipeLvls(checkNumber){
        let returnRecipes_arr:any[]=[]

        for (let x = 0; x < outConts.length; x++) {
          const element = outConts[x];
          const lvlCont = element.getElementsByClassName("recipeLevel")[0];
          const recipeLvl = parseInt(lvlCont.textContent);

          if(recipeLvl == checkNumber){
            returnRecipes_arr.push(element);
          }
        }

        return returnRecipes_arr;
    }

    //...........................................................................................
    //THIS LOCAL FUNCTION FUNCTION IS THE ONE THAT ULTIMATELY HIDES/SHOWS RECIPES
    function fixView(){

        if(searchbarActivity){

          $(outConts).css("display", "none");

          for (let x = 0; x < showRecipes_arr.length; x++) {
            const treatedArray = showRecipes_arr[x];

            for (let i = 0; i < treatedArray.length; i++) {
              const element = treatedArray[i];
              let keywordsElement = element.getElementsByTagName("H6")[0];
              let txtValue = keywordsElement.textContent || keywordsElement.innerText;

              if (txtValue.toUpperCase().indexOf(searchbarActivity.toUpperCase()) > -1) {
                element.style.display = "block";
              } else {
                element.style.display = "none";
              }
            }; // END OF LOOP

          }

        }else{

          $(outConts).css("display", "none");

          for (let x = 0; x < showRecipes_arr.length; x++) {
            const treatedArray = showRecipes_arr[x];

            for (let i = 0; i < treatedArray.length; i++) {
              const element = treatedArray[i];
              $(element).css("display", "block");
            }
          }

        }// END OF ELSE

    }

}

//------------------------------------------------------------------------------

recipesBackToNormal(){

    $(".recipeOutCont").css("display", "block");
    this.filterIsOnBoolean = false;
    this.timeFilterIsOnboolean = false;
    this.noResultsBool = false;

}

//------------------------------------------------------------------------------



timeTBoxNumber(myEvent){

  this.setInputFilter(myEvent.srcElement, function(value) {return /^-?\d*$/.test(value); });
  setTimeout(() => {
    this.timeFilterFunc() 
  });

}


timeFilterFunc(){

    this.myShowArray = [];

    const myCheckbox:any= document.getElementById('cookingTimeCheckbox');
    const checkboxBoolean:boolean = myCheckbox.checked;

    if(checkboxBoolean){//If time checkbox is checked
      $("#theSearchbar").val("");

      //Loop over all outConts
      $.each(document.getElementsByClassName('recipeOutCont'), (index, element)=>{
        const myRecipeTimeInt = parseInt(element.getElementsByClassName("recipeTimeP")[0].textContent);
        const myRecipeLevelElem = element.getElementsByClassName('recipeLevelP')[0];
        const myRecipeLevel = parseInt(myRecipeLevelElem.textContent.replace(/Level/g, ""))

        if(this.recipeLevels_arr.length > 0){  //If level filter is on
          for(let i = 0; i < this.recipeLevels_arr.length; i++){
            let thisRecipeLevel = this.recipeLevels_arr[i];

            if(thisRecipeLevel == myRecipeLevel &&
            myRecipeTimeInt <= this.maxValTimeFilter &&
            myRecipeTimeInt >= this.minValTimeFilter){
              this.myShowArray.push(element.id); //If element matches, add to show array
            }else{
              $(element).css('display', "none"); // Otherwise, hide
            }
          }
        }else{//If level filter isn't on (If only time checkbox is active)
          if(myRecipeTimeInt <= this.maxValTimeFilter &&
          myRecipeTimeInt >= this.minValTimeFilter ){
            this.myShowArray.push(element.id); //If element matches, add to show array
          }else{
            $(element).css('display', "none"); // Otherwise, hide
          }
        }
      });//End of each
      this.timeFilterIsOnboolean = true;
    }else if(this.recipeLevels_arr.length > 0){ //If time checkbox is not checked but levels are active
      this.filterRecipes();
    }else{ //If nothing's active
      this.recipesBackToNormal();
    }

    //...........................................................................................

    if(this.myShowArray.length >= 1){
      $.each(this.myShowArray, (ind, elem)=>{
        let recipeToDisplay = document.getElementById(elem);
        $(recipeToDisplay).css("display", "block");
      })
      this.noResultsBool = false;
    }else if(this.recipeLevels_arr.length > 0){
    }else{
      this.noResultsBool = true;
    }

}

//------------------------------------------------------------------------------

filterIsOnFunc(){

    if(this.recipeLevels_arr.length > 0 || this.timeFilterIsOnboolean == true){
      this.filterIsOnBoolean = true;
    }else{
      this.filterIsOnBoolean = false;
    }

}

//------------------------------------------------------------------------------

onSearchbarKeyup(myEvent:any, mySearchbar?:any){

    let mySearchbarElement:any;
    let searchValue:string;

    if(mySearchbar){
        mySearchbarElement= mySearchbar;
        searchValue = this.searchbarValue;
    }else{
        mySearchbarElement= myEvent.srcElement;
        searchValue = mySearchbarElement.value;
    }

    //...........................................................................................
    //Making everything back to normal if any filters were active (FOR NOW)

    $("#cookingTimeCheckbox").prop("checked", false);
    this.recipesBackToNormal();
    this.myShowArray = [];

    if(this.recipeLevels_arr.length > 0){
      $.each(this.recipeLevels_arr, (ind, elem)=>{
        const theElem = document.getElementById(elem); $(theElem).click();
      })
      this.filterRecipes();
    }

    //...........................................................................................

    for(let i = 0; i < document.getElementsByClassName('recipeOutCont').length; i++){

      let element = document.getElementsByClassName('recipeOutCont')[i];
      let keywordsElement:any= element.getElementsByTagName("H6")[0];
      let txtValue = keywordsElement.textContent || keywordsElement.innerText;

      if (txtValue.toUpperCase().indexOf(searchValue.toUpperCase()) > -1) {
        this.myShowArray.push(element);
      } else {
        $(element).css("display", "none");
      }

    }

    if(this.myShowArray.length > 0){
      $.each(this.myShowArray, (ind, elem)=>{
      let recipeToDisplay = document.getElementById(elem);
      $(recipeToDisplay).css("display", "block");
      })
      this.noResultsBool = false;
    }else{
      this.noResultsBool = true;
    }


    if(myEvent.keyCode == 13){
      $("#theSearchbar").blur();
    }

}

//------------------------------------------------------------------------------

checkIfCategory(){

  if(this.route.snapshot.params['category']){
    this.searchbarValue =  this.route.snapshot.params['category'];
    this.route.params.subscribe((theParams: Params)=>{
      this.searchbarValue = theParams['category'];
    })
  }

}

//------------------------------------------------------------------------------

 setInputFilter(textbox: Element, inputFilter: (value: string) => boolean): void {
  ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
      textbox.addEventListener(event, function(this: (HTMLInputElement | HTMLTextAreaElement) & {oldValue: string; oldSelectionStart: number | null, oldSelectionEnd: number | null}) {
          if (inputFilter(this.value)) {
              this.oldValue = this.value;
              this.oldSelectionStart = this.selectionStart;
              this.oldSelectionEnd = this.selectionEnd;
          } else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
              this.value = this.oldValue;
              if (this.oldSelectionStart !== null &&
                  this.oldSelectionEnd !== null) {
                  this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
              }
          } else {
              this.value = "";
          }
      });
  });
}

//------------------------------------------------------------------------------

closeFoodtionaryTutorial(){
$("#mobileRecipesTutorialCont").slideUp(400);
localStorage.newToFoodtionary = false; 
} 

//------------------------------------------------------------------------------

ifTutorialNeededFunc(){

if(localStorage.newToFoodtionary == undefined){
$("#mobileRecipesTutorialCont").slideDown(500)
}

}


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................

} //END OF CLASS
