import { CategoriesService } from './../../services/categories.service';
import { GlobalService } from './../../services/global.service';
import { CountryContinentService } from './../../services/country-continent.service';
import { RecipesService } from './../../services/recipes.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit, AfterViewInit, OnChanges, DoCheck, OnDestroy } from '@angular/core';
import  * as $ from 'jquery'


@Component({
  selector: 'app-n-mobile-recipe',
  templateUrl: './n-mobile-recipe.component.html',
  styleUrls: ['./n-mobile-recipe.component.css']
})
export class NMobileRecipeComponent implements OnInit, AfterViewInit, OnDestroy {



  constructor(private route:ActivatedRoute,
                      private RecipesService: RecipesService,
                      private CountryContinentService: CountryContinentService,
                      private GlobalService:GlobalService,
                      private myRouter: Router,
                      private CategoriesService: CategoriesService) { }


// *********************************************************************************************
//LOCAL VARIABLES


                      myRecipeId:number;
                      myRecipeObj:any;
                      myRecipeTotalTime:any;
                      myRecipeTotalTimeHr:any;
                      myRecipeContinent:string;
                      ingToolVar:boolean= false;
                      viewRecipeDetails:boolean=false;
                      recipeCookingType:any;
                      cookingTypeMessage:any = undefined;
                      choosingRecipeType:boolean=false;

// *********************************************************************************************

  ngOnInit() {

    this.GlobalService.allWhiteBg = true;

    if(this.route.snapshot.params['recipeId'])   {
      this.myRecipeId = this.route.snapshot.params['recipeId'];
      this.myRecipeObj = this.RecipesService.theRecipes[this.myRecipeId - 1]
      this.myRecipeTotalTime = parseInt( this.myRecipeObj.prepTime ) + parseInt( this.myRecipeObj.cookTime ) 
      this.myHourlyTime()
      // this.myRecipeTotalTimeHr = (this.myRecipeTotalTime / 60).toFixed(2);
      for (let i = 0; i < this.CountryContinentService.countryContinentsArr.length; i++) {
        const obj = this.CountryContinentService.countryContinentsArr[i];
        let nameToTest = obj.country.replace(/ /g, '').toLowerCase();
  
        if(nameToTest == this.myRecipeObj.countryOfOrigin.replace(/ /g, '').toLowerCase() ){
          this.myRecipeContinent = obj.continent;
        }
      }
  
      this.route.params.subscribe((theParams: Params)=>{
        this.myRecipeId = theParams['recipeId'];
      })
  }

  }

//------------------------------------------------------------------------------

  ngAfterViewInit(){

    this.GlobalService.scrollTop()

      this.placeMyImageFunc( "mainRecipeImg", this.myRecipeObj.recipeName, "./../../../imgs/food/startRecipes/", "_1.jpg")
      this.placeMyImageFunc( "nutritionImg", this.myRecipeObj.recipeName, "./../../../imgs/food/nutrition/recipes/", ".png")
      this.placeMyImageFunc( "ingredientImage",  "n/a" , "./../../../imgs/food/ingredients/", ".jpg", "ingredientsList")
      this.placeMyImageFunc( "toolImage",  "n/a" , "./../../../imgs/tools/", ".png", "utensilsList")
      this.GlobalService.imageInNamePlacementFunc(true, "recipeCountryOfOriginImg","./../../../imgs/location/countries/", "png" )
      this.fixCreatorImg()
      
  } 

//------------------------------------------------------------------------------

  ngOnDestroy(){
    this.GlobalService.allWhiteBg = false;
  }

// *********************************************************************************************

  placeMyImageFunc(myContIdOrClass:string, myImageName:string, myImagePath: string, myImageType:string, specialList?:string){

      if(!specialList){
        $("#" + myContIdOrClass).css("background-image", "url(" + myImagePath + myImageName.replace(/ /g, '').toLowerCase() + myImageType + ")")
      }else {
        const myClassList:any = $("." + myContIdOrClass);        

        for(let i = 0; i < myClassList.length; i++ ){
          $(myClassList[i]).css("background-image", "url(" + myImagePath + myClassList[i].textContent.replace(/ /g, "").toLowerCase() +  myImageType + ")");
          myClassList[i].textContent = "";
      }
 
    }
      
      
    //   if(specialList == "ingredientsList" && myContIdOrClass == "ingredientImage"){
    //   const myClassList:any = $("." + myContIdOrClass);        
    //     for(let i = 0; i < myClassList.length; i++ ){
    //       $(myClassList[i]).css("background-image", "url(" + myImagePath + myClassList[i].textContent.replace(/ /g, "").toLowerCase() +  myImageType + ")");
    //       myClassList[i].textContent = "";
    //     }
    // } else if(specialList == "utensilsList" && myContIdOrClass == "toolImage"){
    //   const myClassList:any = $("." + myContIdOrClass);        
      
    //   }
    // }
    
    

    
  }

//------------------------------------------------------------------------------

  toggleIngsAndTools(myEvent:any){

    let elementTouched = myEvent.srcElement;

    if(elementTouched.id == "ingAndToolSwitch"){
      this.ingToolVar = !this.ingToolVar;
    }else if(elementTouched.id == "utensilsChoose"){
      this.ingToolVar = true;
    }else if(elementTouched.id == "ingredientsChoose"){
      this.ingToolVar = false;
    }


  }

//------------------------------------------------------------------------------

  startCookingFunc(recipeCookingType:string){

    $("#mainOutterCont").animate({
                width: "toggle"
            }, 600);
    setTimeout(() => {
    $("#cookingTypesCont").animate({
      width: "toggle"
  }, 600);
    },500);

    this.GlobalService.scrollTop()
  
    this.choosingRecipeType = true;
    console.log(this.choosingRecipeType)
    }
     
//------------------------------------------------------------------------------
 
    chooseCookingType(myEvent:any){

      let myElement = myEvent.srcElement;
      let myRecipeType = myElement.textContent;
      let myParentCont = myElement.parentElement;
  
      $('#cookingTypeMessageT').css('display', "block");
      $("#cookingTypeMessageT").removeClass('errorMessage');
      $(myElement).addClass('activeTypeSelection');
      this.recipeCookingType = myRecipeType.replace(/ /g, '').trim();
  
  
      for (let i = 0; i < myParentCont.children.length; i++) {
        const element = myParentCont.children[i];
        $(element).removeClass('activeTypeSelection')
      }
  
      for (let i = 0; i < this.CategoriesService.RecipeCategories.length; i++) {
        const element = this.CategoriesService.RecipeCategories[i];
  
        if(element.name.replace(/ /g, '').toLowerCase().trim() == this.recipeCookingType.toLowerCase()){
          this.cookingTypeMessage = element.description;
        }
      }
  
      console.log(this.recipeCookingType)
  }

//------------------------------------------------------------------------------

  showRecipe(){
    $("#cookingTypesCont").slideUp(600)
    setTimeout(() => {
      if(this.recipeCookingType == 'simplerecipe'){
        this.myRouter.navigate(["/simple-recipe-m", this.myRecipeObj.recipeId ])
      }else if(this.recipeCookingType == 'video'){
        this.myRouter.navigate(["/video-m", this.myRecipeObj.recipeId ])
      }
    }, 800);
  }

//------------------------------------------------------------------------------

  onBackArrow(){
    $("#cookingTypesCont").animate({
    width: "toggle"
    }, 600);
    setTimeout(() => {
    $("#mainOutterCont").animate({
    width: "toggle"
    }, 600);
    },500);
    this.GlobalService.scrollTop()
  this.choosingRecipeType = false;
  }


  fixCreatorImg(){
    if(this.myRecipeObj.recipeCreator !== 'original' && this.myRecipeObj.creatorImgUrl !== 'undefined'){
    $("#recipeCreatorImg").css('background-image', 'url('+this.myRecipeObj.creatorImgUrl+')')
    }
  }


  myHourlyTime(){
    if(this.myRecipeTotalTime >= 60){

      let hrTime:any= (this.myRecipeTotalTime / 60).toFixed(2);
      let wholeNumber:any= Math.floor(hrTime)


      if((hrTime - wholeNumber) == .5){
        hrTime = hrTime;
      }else if((hrTime - wholeNumber) < .5){
        hrTime = Math.floor(hrTime);
      }else{
        hrTime = Math.ceil(hrTime);
      }
      this.myRecipeTotalTimeHr = hrTime;
    }
  }
 

// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS