import { GlobalService } from './../../services/global.service';
import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-utensils',
  templateUrl: './utensils.component.html',
  styleUrls: ['./utensils.component.css']
})
export class UtensilsComponent implements OnInit, AfterViewInit {

//LOCAL VARIABLES

  @Input('myUtensilsArr')  utensilsArr;


// *********************************************************************************************

constructor(private GlobalService: GlobalService) { }

// *********************************************************************************************
//LIFECYCLE HOOKS

  ngOnInit() {
  }


  ngAfterViewInit(){
    this.GlobalService.placeMyImage('toolDispImg', './../../../imgs/tools/',  'nameNormal', ".png", false);

  }

// *********************************************************************************************
//LOCAL FUNCTIONS


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS


