import { Title } from '@angular/platform-browser';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterViewInit, OnChanges, DoCheck, ɵConsole } from '@angular/core';
import * as $ from 'jquery'

import { GlobalService } from './../../../services/global.service';
import { RecipesService } from './../../../services/recipes.service';

@Component({
  selector: 'simple-recipe-d',
  templateUrl: './simple-recipe-d.component.html',
  styleUrls: ['./simple-recipe-d.component.css', './responsive-simple-recipe-d.component.css']
})

export class SimpleRecipeDComponent implements OnInit, AfterViewInit, DoCheck{

  constructor(private myRoute: ActivatedRoute, 
                      private  RecipesService: RecipesService, 
                      private GlobalService: GlobalService,
                      private Router:Router,
                      private TitleService: Title) { }
 

//GLOBAL VARIABLES
// *********************************************************************************************

  myRecipeObj:any;
  recipeId:number;
  activeIngsBtn:string = "need";
  amountOfServingsVar:number=1;
  mandatoryAmountVar:number = 1;
  myStepsArr=[];
  myStepsObj={};
  theCounter:number=0;

//LIFECYCLES
// *********************************************************************************************

ngOnInit() {
    $("#mainRecipeCont").slideDown(500);
    $("#mainRecipeCont").css("display", "flex");

    if(this.myRoute.snapshot.params['recipeId'])   {
      this.recipeId = this.myRoute.snapshot.params['recipeId'];
      this.myRecipeObj = this.RecipesService.theRecipes[this.recipeId - 1]

    this.TitleService.setTitle("MunCH!  · " + this.GlobalService.capitalizeWholeStringSpaces(this.myRecipeObj.recipeName))

      this.myRoute.params.subscribe((theParams: Params)=>{
      this.recipeId = theParams['recipeId'];
      });

    }

    this.amountOfServingsVar = this.myRecipeObj.pplPerRecipe;
}

//------------------------------------------------------------------------------

ngAfterViewInit(){
    this.RecipesService.recipeSteps[this.myRecipeObj.recipeId];
    this.recipeStepsFunc();
    this.GlobalService.imageInNamePlacementFunc(true, 'mainRecipeImg', '../../../../imgs/food/startRecipes/', 'jpg', true);
    // this.GlobalService.imageInNamePlacementFunc(false, 'ingImage', '../../../../imgs/food/ingredients/',  'jpg');
    this.ingImageFunc()
    this.writeIngredientsStartMeasure();
    this.fractionMyIngredientMeasurements();

}

//------------------------------------------------------------------------------

ngDoCheck(){

    if(this.amountOfServingsVar == null || this.amountOfServingsVar == 0){
      this.mandatoryAmountVar = 1;
    }else{
      this.mandatoryAmountVar = this.amountOfServingsVar;
    }

}

//GLOBAL FUNCTIONS
// *********************************************************************************************

changeIngsBtnFunc(needIngsBool:boolean){

    if(needIngsBool){
      this.activeIngsBtn = 'need'
    }else{
      this.activeIngsBtn = 'opt'
    }

    setTimeout(() => {
      // this.GlobalService.imageInNamePlacementFunc(false, 'ingImage', '../../../../imgs/food/ingredients/',  'jpg');
      this.ingImageFunc();
    });
}

//------------------------------------------------------------------------------

filterPplInputFunc(e:any){
  var x=e.which||e.keycode;

  if(e.srcElement.value.length == 0 && e.keyCode == 48){ //If user types 0 after textbox is empty
    return false;
  }else if(( (x>=48 && x<=57) || x==8 || (x>=35 && x<=40)|| x==46 ) && (e.srcElement.value.length <= 1 || e.keyCode == 8)){
    return true;
  }else{
    return false;
  }

}

//------------------------------------------------------------------------------

recipeStepsFunc(){

    for (let i = 0; i < this.RecipesService.recipeSteps.length; i++) {
      const element = this.RecipesService.recipeSteps[i];

      if(element.recipeId == this.myRecipeObj.recipeId){
        this.myStepsObj = element;
      }

    }


    for (let i = 0; i < Object.keys(this.myStepsObj).length; i++) {
      const element = Object.keys(this.myStepsObj)[i];

      if(i > 0){
        let myString = this.myStepsObj[element]
          let str1 = myString.split('.');
          let str2 = "";



          
          $.each(str1,(i)=>{
            let lastChar = str1[i].substr(str1[i].length - 1);
          if(i !== str1.length - 1 && lastChar !== "!"){
            str2 += this.GlobalService.capitalizeString($.trim(str1[i]))+'. ';
          }

          // console.log(str1)

          if(str1.length == 1){
            str2 += str1[i] + ". "
          }

          });
        
          if(!(myString.length <= 6)){ // IF NOT MunCH! on last step...
            this.myStepsArr.push(str2);
          }else{
            this.myStepsArr.push(myString);
          }
      }
      
    }

    setTimeout(() => {
      let myStepPs = $('.myStepP');
      
      for(let i=0; i < myStepPs.length; i++ ){
        let currElem = myStepPs[i]
        currElem.innerHTML = this.myStepsArr[i]
      }
      
    });

}

//------------------------------------------------------------------------------

onBackArrow(){
  this.GlobalService.onBackHistory();
}

//------------------------------------------------------------------------------

itIncludes(stringToTest:string, testedValue:string){
  return stringToTest.toLowerCase().replace(/ /g, '').includes(testedValue.toLowerCase().replace(/ /g, ''));
}

//------------------------------------------------------------------------------
 
onPplInputChange(){
  this.fixIngredientAmountFunc();
  this.adjustIngredientAmountFunc();
  this.fractionMyIngredientMeasurements();
}

//------------------------------------------------------------------------------

writeIngredientsStartMeasure(){
  let myNeedConts= $(".myServingAmountValNeed");
  let myOptConts= $(".myServingAmountValOpt");

  for (let i = 0; i < myNeedConts.length; i++) {
    const element = myNeedConts[i];   
    element.textContent = this.myRecipeObj.neededIngredients[i].amountPerPerson;   
  }

  for (let i = 0; i < myOptConts.length; i++) {
    const element = myOptConts[i];   
    element.textContent = this.myRecipeObj.optionalIngredients[i].amountPerPerson;   
  }

}


//------------------------------------------------------------------------------

fractionMyIngredientMeasurements(){

  let myIngMeasures = $(".myServingAmountVal");

  for(let i = 0; i < myIngMeasures.length; i++){

      let myNumToRound: string | number= parseFloat(myIngMeasures[i].textContent);
      let myRoundedNum:number= Math.round((myNumToRound+ Number.EPSILON) * 100) / 100
      let myWholeNum:number= Math.trunc(myRoundedNum);
      let numToTest:string | number;
      let numToPlace:any;
      let floatToPlace:any = undefined;

      let myIngObj  = this.myRecipeObj.neededIngredients[i] ;

    if(this.myRecipeObj.neededIngredients[i] == undefined){
      let theNumbertoPlacex = i - this.myRecipeObj.neededIngredients.length;
       myIngObj =  this.myRecipeObj.optionalIngredients[theNumbertoPlacex];
    }

    // console.log(myIngObj)
      // console.log(myIngMeasures[i])


      if(Number.isInteger(myRoundedNum)){ // If number is integer
        numToTest = '';
      }else if(myIngObj.amountMeasure == 'totaste'){
        numToTest = 'totaste'
      }else if(myIngObj.amountMeasure == 'forfrying'){
        console.log("Yes")
        numToTest = 'forfrying'
      }else{ //If number is float
        numToTest = myRoundedNum - myWholeNum;   
      }

      


      if(numToTest > 0 && numToTest <= .179 ){ //if number is between 0 and .79 [rounded to .125] (Mediary point between .125 and .25)
        floatToPlace =  '⅛';
      }else if(numToTest >= .187 && numToTest <= .289 ){//if number is between .187 and .289 [rounded to .25] (Mediary point between .25 and .33)
        floatToPlace =  '¼';
      }else if(numToTest >= .290 && numToTest <= .415 ){//if number is between .290 and .415 [rounded to .33 (⅓)] (Mediary point between .33 and .5)
        floatToPlace =  '⅓';
      }else if(numToTest >= .416 && numToTest <= .582 ){//if number is between .416 and .582 [rounded to .5] (Mediary point between .5 and .66)
        floatToPlace =  '½';
      }else if(numToTest >= .583 && numToTest <= .707 ){//if number is between .583 and .707 [rounded to .66 (⅔)] (Mediary point between .66 and .75)
        floatToPlace =  '⅔';
      }else if(numToTest >= .708 && numToTest <= .874 ){//if number is between .708 and .824 [rounded to .75] (Mediary point between .75 and 1)
        floatToPlace =  '¾';
      }else if(numToTest >= .875 && numToTest <= .99 ){//if number is between .875 and .99 [rounded to 1] (Greater mediary point between .75 and 1)
        numToPlace = myWholeNum + 1;
      }else if(numToTest == "totaste"){
        numToPlace = "To Taste"
      }else if(numToTest == "forfrying"){
        numToPlace = "For Frying"
      }else{
        numToPlace = myWholeNum;
      }

      if(myWholeNum !== 0 && floatToPlace !== undefined){
        numToPlace = myWholeNum.toString() + floatToPlace;
      }else if(floatToPlace !== undefined){
        numToPlace = floatToPlace;
      }

        myIngMeasures[i].innerHTML = numToPlace;
      }

      }

      //------------------------------------------------------------------------------

      fixIngredientAmountFunc(){

      let myIngMeasures = $(".myServingAmountVal");

      for(let i = 0; i < myIngMeasures.length; i++){

      let myFixableString = myIngMeasures[i].textContent;
      let myFixedVal:string = undefined;

      if(myFixableString.includes('⅛')){
        myFixedVal = myFixableString.replace(/⅛/g, '.125');
      }else if(myFixableString.includes('¼')){
        myFixedVal = myFixableString.replace(/¼/g, '.25');
      }else if(myFixableString.includes('⅓')){
        myFixedVal = myFixableString.replace(/⅓/g, '.33');
      }else if(myFixableString.includes('½')){
        myFixedVal = myFixableString.replace(/½/g, '.5');
      }else if(myFixableString.includes('⅔')){
        myFixedVal = myFixableString.replace(/⅔/g, '.66');
      }else if(myFixableString.includes('¾')){
        myFixedVal = myFixableString.replace(/¾/g, '.75');
      }else{
        // console.log("Nothing to do here...");
      }

      if(myFixedVal !== undefined){
        myIngMeasures[i].textContent = myFixedVal;
    }else if(isNaN(myFixableString)){
      // console.log(myFixableString)
    }
    
  }

}

//------------------------------------------------------------------------------

adjustIngredientAmountFunc(){

  let myIngMeasures = $(".myServingAmountVal");

  for(let i = 0; i < myIngMeasures.length; i++){
    let myElement = myIngMeasures[i];
    let myCurrentValue = myElement.textContent;



    if(!isNaN(myCurrentValue)){
      if(myIngMeasures[i].classList.contains('myServingAmountValNeed')){
        myIngMeasures[i].textContent = (this.myRecipeObj.neededIngredients[parseInt(myIngMeasures[i].id.replace(/_needIng/g, ''))].amountPerPerson * this.mandatoryAmountVar) / this.myRecipeObj.pplPerRecipe;
      }else{
        myIngMeasures[i].textContent = (this.myRecipeObj.optionalIngredients[parseInt(myIngMeasures[i].id.replace(/_optIng/g, ''))].amountPerPerson * this.mandatoryAmountVar) / this.myRecipeObj.pplPerRecipe;
      }
    }else{
  myIngMeasures[i].textContent = myCurrentValue;
  // console.log(myIngMeasures[i].textContent)
    }

 


  }



}

//------------------------------------------------------------------------------

ingImageFunc(){
  let ingImages = $(".ingImage");
  let needIngsArr = [];
  let optIngsArr = [];

  for (let i = 0; i < ingImages.length; i++) { //SEPARATING OPTIONAL AND NEEDED IMAGE CONTS
    const element = ingImages[i];   
    if(element.classList.contains('optionalIngImage')){
      optIngsArr.push(element);
    }else{
      needIngsArr.push(element);
    }
    
  }
  
  if(this.activeIngsBtn == "need"){
    for (let i = 0; i < this.myRecipeObj.neededIngredients.length; i++) {
      const element = needIngsArr[i];   
      $(element).css("background-image", 
      "url(./../../../imgs/food/ingredients/"+ this.myRecipeObj.neededIngredients[i].name.replace(/ /g, '').toLowerCase()+".jpg)"
      );
    }
  }else if(this.activeIngsBtn == "opt"){
    for (let i = 0; i < this.myRecipeObj.optionalIngredients.length; i++) {
      const element = optIngsArr[i];   
      $(element).css("background-image", 
      "url(./../../../imgs/food/ingredients/"+ this.myRecipeObj.optionalIngredients[i].name.replace(/ /g, '').toLowerCase()+".jpg)"
      );
    }
  }else{
    console.error("ERROR 208,  INVALID activeIngsBtn Bool input");
  }

}



ifShowIngDetailedInfo(ingredient){

  
  
  if(ingredient.amountMeasure == 'forfrying' ){
    return false;
  }else if(ingredient.amountMeasure == 'totaste'){
    return false;
  }else if(ingredient.amountMeasure == ''){
    console.log(ingredient)
    return false;
  }else{
    return true;
  }



  

}





// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS

