import { GlobalService } from './../../services/global.service';
import { Component, OnInit, AfterViewInit, DoCheck } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})

export class BodyComponent implements OnInit, AfterViewInit, DoCheck {

  constructor(private GlobalService:GlobalService) { }

// *********************************************************************************************

screenWidth:number=this.GlobalService.wWidth;
private allWhiteBg:boolean=false;
subscrition: Subscription;

// *********************************************************************************************

ngOnInit() {

}

//------------------------------------------------------------------------------

ngAfterViewInit(){

}

//------------------------------------------------------------------------------


ngDoCheck(){
  this.allWhiteBg = this.GlobalService.getMyBgColor();
}

// *********************************************************************************************








  


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
