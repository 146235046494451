import { IngredientsService } from '../../../../../services/ingredients.service';
import { ActivatedRoute } from '@angular/router';
import { RecipesService } from '../../../../../services/recipes.service';
import { Component, Input, OnChanges, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-ing-detail-meas-opt',
  templateUrl: './ing-detail-meas-opt.component.html',
  styleUrls: ['./ing-detail-meas-opt.component.css', './../../../../ingredients/ingredients.component.css']
})
export class IngDetailMeasComponentOpt implements OnInit, OnChanges, AfterViewInit {

  @Input("thePassedIngs") myPassedIngs:any=[];
  @Input("servingsAmount") peopleAmount:any;
  afterStart:boolean=false;
  myServingsMultiplier:any; 
  myRecipeId:any = undefined;
  recipeObj:any=[];
  

// *********************************************************************************************

constructor(private IngredientsService:IngredientsService,
  private ActivatedRoute: ActivatedRoute,
  private RecipesService: RecipesService) { }



// *********************************************************************************************
   
  ngOnChanges(){
    
    
    if(this.afterStart){
      this.IngredientsService.onPplInputChange("ingredientMeasurementPDetailOpt", parseInt(this.peopleAmount) / this.recipeObj.pplPerRecipe, this.myPassedIngs)
      // console.log(parseInt(this.peopleAmount) +" VS "+ this.recipeObj.pplPerRecipe )

    }

  }

//------------------------------------------------------------------------------

ngOnInit() {
  

  // Variable setting
if(this.ActivatedRoute.snapshot.params['recipeId'])   {
  this.myRecipeId = this.ActivatedRoute.snapshot.params['recipeId'];
  this.recipeObj = this.RecipesService.theRecipes[this.myRecipeId - 1];
}

this.myServingsMultiplier =  parseInt(this.peopleAmount) / this.recipeObj.pplPerRecipe;

}



//------------------------------------------------------------------------------

 
ngAfterViewInit(){

  this.IngredientsService.fractionMyIngredientMeasurements("ingredientMeasurementPDetailOpt");
  this.afterStart = true;

}


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS

 