import { IngredientsService } from './../../../../services/ingredients.service';
import { Component, OnInit, Input, AfterViewInit, DoCheck, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecipesService } from './../../../../services/recipes.service';
import { GlobalService } from './../../../../services/global.service';
import * as $ from 'jquery';

@Component({
  selector: 'simple-recipe',
  templateUrl: './simple-recipe.component.html',
  styleUrls: ['./simple-recipe.component.css']
})  


export class SimpleRecipeComponent implements OnInit, AfterViewInit, DoCheck {
   
constructor(private GlobalService:GlobalService,
                    private RecipesService:RecipesService, 
                    private ActivatedRoute:ActivatedRoute,
                    private IngredientsService:IngredientsService,
                    private router:Router) { }

// *********************************************************************************************

    recipeObj:any;
    peopleAmt:number=1;
    myStepsObj:any=this.RecipesService.recipeSteps;
    myStepsArr:any[]=[];
    currentStep:string;
    currentStepNumber:number=0;
    mobileRecipesToggle:boolean = false;
    myRecipeId:any; 
    servingNumbers:any=[];

// *********************************************************************************************

ngOnInit() {


  $("#myMainRecipeCont").slideDown(500);
  $("#myMainRecipeCont").css('display', "inline-block");


  for (let x= 0; x < 100; x++) {
    if(x !== 0){
      this.servingNumbers[x] = x;    
    }
  }

  if(this.ActivatedRoute.snapshot.params['recipeId'])   {
    this.myRecipeId = this.ActivatedRoute.snapshot.params['recipeId'];
    this.recipeObj = this.RecipesService.theRecipes[this.myRecipeId - 1]
    this.peopleAmt = this.recipeObj.pplPerRecipe;
  }

    for (let i = 0; i < this.RecipesService.recipeSteps.length; i++) {
      const element = this.RecipesService.recipeSteps[i];

      if(element.recipeId == this.recipeObj.recipeId){
        this.myStepsObj = element;
        // this.IngredientsService.currentRecipeID = this.recipeObj.recipeId;
        // console.log( this.IngredientsService.currentRecipeID);
      }
    }


    for (let i = 0; i < Object.keys(this.myStepsObj).length; i++) {
      const element = Object.keys(this.myStepsObj)[i];

      if(i > 0){
        let myString = this.myStepsObj[element]
        this.myStepsArr.push(myString);
      }
    }

    this.currentStep = this.fixMyStep(this.myStepsArr[0]);

} 

// ----------------------------------------------------------------------------------------

ngAfterViewInit(){

    this.GlobalService.fixImages('myRecipeImg', 'text', "../../../../imgs/food/startRecipes/" , "_1.jpg")
    this.GlobalService.fixImages('ingBackImg', 'text', "../../../../imgs/food/ingredients/" , ".jpg");

//...........................................................................................
//Remove UNDEFINED element from select dropdown
const mySelects:any= $(".mySelectOpt");
for (let i = 0; i < mySelects.length; i++) {
  const element = mySelects[i];
  if(element.value == 'undefined'){
    this.removeElement(element);
  }
}

}


ngDoCheck(){
// console.log("Amount of people => " + this.peopleAmt)
}

// *********************************************************************************************
//STATIC FUNCTIONS

  stepChange(theEvent:any, upOrDownVal?:any){

    let myDesiredStep = theEvent.srcElement.value;
    let currentNumberStepSelect:any= document.getElementById('stepsSelect');

    if(theEvent.srcElement.id == 'stepsSelect'){ //IF WE USED SELECT
      this.currentStepNumber = +myDesiredStep ;
      this.currentStep = this.fixMyStep(this.myStepsArr[this.currentStepNumber]);
    }else{
        
      if(upOrDownVal == 0 && this.currentStepNumber >= 1 ){
        this.currentStepNumber = this.currentStepNumber - 1
        this.currentStep = this.fixMyStep(this.myStepsArr[this.currentStepNumber]);
        console.log(+currentNumberStepSelect + 1)
      }else if(upOrDownVal == 1 && this.currentStepNumber < this.myStepsArr.length - 1 ){
        this.currentStepNumber = this.currentStepNumber + 1;
        this.currentStep = this.fixMyStep(this.myStepsArr[this.currentStepNumber]);
      }else{
        console.error("Wrong Argument Passed To Function OR Invalid input change.");
      }
    }

    this.currentStep = this.capitalizeFirstLetter(this.currentStep);

}

// ----------------------------------------------------------------------------------------

capitalizeFirstLetter(string:string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// ----------------------------------------------------------------------------------------

fixMyStep(string:string) {

let returnString:string;
// console.log(string)
let ingredientsSpanIndex = string.indexOf("<span>");

if(ingredientsSpanIndex == -1){
  returnString = string;
}else{
  returnString =  string.substr(0, ingredientsSpanIndex);
}

return returnString.charAt(0).toUpperCase() + returnString.slice(1);

}

// ----------------------------------------------------------------------------------------

fixMunCHFont(){

  if(this.currentStep.length == 6){
    return true
  }else{
    return false;
  }

}

// ----------------------------------------------------------------------------------------

updateIngAmounts(myEvent: any ){
  let mySelect:any = myEvent.srcElement;

  if(mySelect.value == 'undefined'){
    mySelect.value = 1;
  }else if(mySelect.value == 'undefined' && this.peopleAmt !== 1){
    this.peopleAmt = mySelect.value;
  }else{
    this.peopleAmt = mySelect.value;
  }
}

//------------------------------------------------------------------------------

removeElement(elementItself) {
  // Removes an element from the document
  var element = elementItself;
  element.parentNode.removeChild(element);
}

//------------------------------------------------------------------------------

fixIngredientAmountFunc(){

}

//------------------------------------------------------------------------------

onBackArrow(){
  this.router.navigate(['recipe-m', this.myRecipeId]);
}



// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS

 

