import { Title } from '@angular/platform-browser';
import { element } from 'protractor';
import { Component, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})

export class AboutComponent implements OnInit, OnChanges {

  constructor( private TitleService:Title) { }

// *********************************************************************************************
//STATIC FUNCTIONS

currentTextSelected:string='mission';
currentText:string;
 aboutTextArray:any = [

      {'textFor':'mission',
      'value' : "This idea is the beginning of something huge! My personal goal is to be able to help every person who wants to start cooking by themselves. I am a strong believer in the idea that anyone can cook if they truly desire it. Helping you bring out that inner chef as we are attempting to start a helpful, effective system for the development of our society that will one day provide simple methods to follow, easily accessible features, and countless methods to improve your daily cooking routine."
      },

          
      {'textFor':'vision',
      'value' : "Achieving to satisfy sufficient gastronomical learning, and facilitating the overall cooking procedure for each and every person that uses MunCH! Encouraging the user to eventually feel more comfortable, and preferring to cook at home and expand their culinary creativity over simply ordering food from a restaurant, in general."
      },

          
      {'textFor':'story',
      'value' : "You would be surprised to know that the name didn't originate from anything food-related, kinda. The true story starts back to when I was thinking for the perfect name. One day after I got back from school, I was starving with little to no motivation to look for a recipe online that would give me exact instructions to follow. Instead, I started to look around my kitchen for ingredients that would give me some type of creative spark. Eventually, I just got carried away and ended up cooking a dish so tasty, I couldn't believe I made it myself. Seeing my face in the mirror in complete disbelief and relating that exact look to a painting called, \"The Scream\" As I was laughing while searching up the painting online just to notice it was painted by an artist called Edvard Munch. The second I saw his last name I knew it would be the perfect name for a perfect idea. Not only it sounds good, its simple and it has a story behind it, I just love that." 
      }
    
 ]

// *********************************************************************************************

  ngOnInit() {
    this.fixTheText();
    this.TitleService.setTitle("MunCH! · About")
  }

//------------------------------------------------------------------------------

  ngOnChanges(){
    this.fixTheText()
  }

// *********************************************************************************************

fixTheText(){
  
    let mySelect:any= document.getElementById('chooseInfoDropdown');
    this.currentTextSelected  =mySelect.value
    console.log(this.currentTextSelected)

    for(let i= 0; i < this.aboutTextArray.length; i++){
      const element = this.aboutTextArray[i];

      if(element.textFor == this.currentTextSelected){
        this.currentText = element.value
      }

    }

}




// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
