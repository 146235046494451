import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'video-d',
  templateUrl: './video-d.component.html',
  styleUrls: ['./video-d.component.css']
})
export class VideoDComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
