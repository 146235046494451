import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'step-by-step-d',
  templateUrl: './step-by-step-d.component.html',
  styleUrls: ['./step-by-step-d.component.css']
})
export class StepByStepDComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
