import { OnInit, OnChanges } from '@angular/core';
import { Component } from '@angular/core';

import { GlobalService } from './services/global.service';
import * as $ from 'jquery'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
}) 

export class AppComponent implements OnInit, OnChanges{
 
constructor(private GlobalService:GlobalService){}

// *********************************************************************************************
// STATIC VARIABLES
title = 'MunCH!';

// *********************************************************************************************
//LIFECYCLES

ngOnInit(){

    const self = this;
    // localStorage.clear()
    $(window).resize(function(){
      self.GlobalService.wWidth = window.innerWidth;
      self.fixGlobalVariables();
    });

}

//------------------------------------------------------------------------------

ngOnChanges(){
  console.log("Changing")
}

// *********************************************************************************************
//STATIC FUNCTIONS 

fixGlobalVariables(){

    // Fixing The Mobile User Boolean
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.GlobalService.mobileUser = true;
    }else{
      this.GlobalService.mobileUser = false;
    }

    //...........................................................................................

    if(this.GlobalService.wWidth >= 1031){
      $("#munchNavHeaderUlCont").css("display", "block");
      this.GlobalService.dropNavOpen = true;
    }else{
      $("#munchNavHeaderUlCont").css("display", "none");
      this.GlobalService.dropNavOpen = false;
    }

}



// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
