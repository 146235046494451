import { GlobalService } from './../global.service';
import { Observable } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })


export class ScreenSizeGuard implements CanActivate{

    constructor(private GlobalService:GlobalService,
                        private myRouter:Router,
                        private myRoute:ActivatedRoute){}

canActivate(route:ActivatedRouteSnapshot, routerState:RouterStateSnapshot)
        :Observable<boolean> | Promise<boolean> | boolean{
        const myScreenWidth = this.GlobalService.retrieveScreenWidth();

        if(myScreenWidth <= 1030){
            return true
        }else{
            this.myRouter.navigate(['/recipe-d', route.params['recipeId']])
        }
}


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS