import { GlobalService } from './../../../services/global.service';
import { Title } from '@angular/platform-browser';
import { BlogService } from './../../../services/blog.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  constructor(private BlogService:BlogService,
                      private TitleService: Title, 
                      private GlobalService: GlobalService) { }

// *********************************************************************************************
//STATIC VARIABLES 

    myObj:any;
    myDate = new Date();
    myDay = this.myDate.getDate();
    myDayOfTheWeek = this.myDate.getDay();
    myMonday:number;
    myMonth:any;
    myWeekDaysArray:any = [];
    laterDate:any;


// *********************************************************************************************
// LIFECYCLE HOOKS

ngOnInit() {
  this.getMonday(new Date)
  this.myMonday = this.laterDate.getDate();
  this.myMonth = this.laterDate.getMonth() +1;
  // this.getMyMonday();
  console.log(this.myMonday)
  this.chooseMyObj()
  console.log(this.myObj)
  this.TitleService.setTitle("MunCH!  · " + this.GlobalService.capitalizeWholeStringSpaces(this.myObj.blogTitle))

//     this.chooseCurrentWeek();  
//     this.TitleService.setTitle("MunCH! · " + this.GlobalService.capitalizeWholeStringSpaces(this.myObj.title))
}


// ----------------------------------------------------------------------------------------

chooseCurrentBlog(){

    this.myWeekDaysArray.push(this.myMonday)
    for(let i =1; i < 7; i++){
      this.myWeekDaysArray.push(this.myMonday  + i);
    }

    $.each(this.BlogService.theBlogs, (index, blog)=>{
      if(parseInt(blog.month) == this.myMonth && this.myWeekDaysArray.includes(parseInt(blog.weekStartDay))){
        this.myObj = blog;
      }
    })

}

// ----------------------------------------------------------------------------------------

getMyMonday(){
  this.myMonday = this.myDay - this.myDayOfTheWeek + 1;
}

// ----------------------------------------------------------------------------------------

 getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
  this.laterDate = new Date(d.setDate(diff));
}

// ----------------------------------------------------------------------------------------


chooseMyObj(){

  for (let i = 0; i < this.BlogService.theNewBlogs.length; i++) {
    const blog = this.BlogService.theNewBlogs[i];

console.log(parseInt(blog.weekStartDay) + "VS" + this.myMonday)
console.log(parseInt(blog.month ) + "VS" +  this.myMonth)

    if(parseInt(blog.weekStartDay) == this.myMonday && parseInt(blog.month )== this.myMonth){
      this.myObj = blog;
    }

    
  }





}



// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS


