import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as $ from 'jquery'

import { RecipesService } from './../../services/recipes.service';
import { DishCategory } from './../../models/dish-category.model';
import { CategoriesService } from './../../services/categories.service';
import { GlobalService } from './../../services/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'time-categories',
  templateUrl: './time-categories.component.html',
  styleUrls: ['./time-categories.component.css', './responsive-time-categories.component.css']
})


export class TimeCategoriesComponent implements OnInit, AfterViewInit {

constructor(private GlobalService:GlobalService,
                    private CategoriesService:CategoriesService,
                    private myRouter:Router, 
                    private RecipesService: RecipesService) { }


// *********************************************************************************************
//STATIC VARIABLES

     mealOfTime:string;
     currentCategory:DishCategory[];

// *********************************************************************************************
//LIFECYCLE HOOKS

ngOnInit() {
  this.GlobalService.checkTime();
  this.mealOfTime = this.GlobalService.mealOfTime;
  this.fixCurrentCategory(this.mealOfTime)
}


// *********************************************************************************************
//STATIC FUNCTIONS

ngAfterViewInit(){

  const myOutConts = document.getElementsByClassName('categoryCont');

  for (let i = 0; i < myOutConts.length; i++) {
    const element = myOutConts[i];

    $(element).css('background-image', 
    'url(../../../imgs/food/categories/' + this.currentCategory[i].name.replace(/ /g, '').toLowerCase()+'.jpg')
  }

}

// -----------------------------------------------------------------------------------------------------

fixCurrentCategory(timeMeal:string){
    this.currentCategory = this.CategoriesService[this.mealOfTime.toLowerCase()];
}

// -----------------------------------------------------------------------------------------------------

openCategory(category:any){
  
    console.log(category);

    this.myRouter.navigate(['/recipes', "categories", category.name]);
    this.RecipesService.myActiveCategoryClicked = category.name;

}



// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
