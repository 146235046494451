import { AfterViewInit } from '@angular/core';

/*
There will be excrutiating changes in design. Changes that can only be managed properly by loading a completely
different component in the case of a touch or desktop device. We'll draw that screen size mark at 1030px 

*/

import { Injectable, OnInit, OnChanges } from '@angular/core';
import * as $ from 'jquery'

@Injectable({
  providedIn: 'root'
})

export class GlobalService implements OnInit, OnChanges, AfterViewInit{

  constructor() { }
 
// GLOBAL VARIABLES
public wWidth:number=window.innerWidth;
public mobileUser:boolean;
public dropNavOpen:boolean=false;
public mealOfTime:string;
public timeOfDay:string;
public questionnaireComplete:boolean;
public allWhiteBg:boolean=false;

// STATIC VARIABLES

private  myTime = new Date();

//LIFECYCLES

ngOnInit(){
const self = this;

}

//------------------------------------------------------------------------------

ngOnChanges(){
}

//------------------------------------------------------------------------------

ngAfterViewInit(){

}

//STATIC FUNCTIONS

//GLOBAL FUNCTIONS



//------------------------------------------------------------------------------

checkTime(){
  if (this.myTime.getHours() >=  0 && this.myTime.getHours() <=  3){
      this.mealOfTime = "Snack";
      this.timeOfDay = "morning";
  }else if (this.myTime.getHours() <=  10 && this.myTime.getHours() >=  4){
      this.mealOfTime = "Breakfast";
      this.timeOfDay = "morning";
  }else if(this.myTime.getHours() ==  11 ){
      this.mealOfTime = "Brunch";
      this.timeOfDay = "morning";
  }else if(this.myTime.getHours() >=  12 &&  this.myTime.getHours() <=  15){
      this.mealOfTime = "Lunch";
      this.timeOfDay = "afternoon";
  }else if(this.myTime.getHours() >=  16 &&  this.myTime.getHours() <=  18){
      this.mealOfTime = "Snack";
      this.timeOfDay = "evening";
  }else if(this.myTime.getHours() >=  19 &&  this.myTime.getHours() <=  23){
      this.mealOfTime = "Dinner";
      this.timeOfDay = "night";
  }else {
    console.log(this.myTime.getHours() )
  }

  // console.log(this.mealOfTime)
}

//------------------------------------------------------------------------------

retrieveLocalStorage(valueInString:string){
  return localStorage.getItem(valueInString);
}

//------------------------------------------------------------------------------

isUserOld(){
  if(localStorage.getItem("oldUser")){
    return true
  }else{
    localStorage.setItem("oldUser", "true")
   return  false;
  } 
}

//------------------------------------------------------------------------------

isQuestionnaireComplete(){
  if(localStorage.getItem('questionnaireComplete')){
    return true;
  }else{
    return false;
  }
}

//------------------------------------------------------------------------------

retrieveScreenWidth(){
   this.wWidth=window.innerWidth;
   return this.wWidth;
}

//------------------------------------------------------------------------------

fixImages(arrayOfContsClass:string, typeOfIntegration:string, folderPath:string, imgFormat:string){
  let myArrayOfConts = document.getElementsByClassName(arrayOfContsClass);


      for (let i = 0; i < myArrayOfConts.length; i++) {
          const element = myArrayOfConts[i];
          let myElemIntegration;
          if(typeOfIntegration == 'id'){
            myElemIntegration = element.id;
          }else if(typeOfIntegration == 'text'){
            myElemIntegration = element.textContent;
          }
    
          const myImgName = myElemIntegration.replace(/ /g, '').toLowerCase();
          $(element).css('background-image', 'url('+ folderPath +myImgName+imgFormat+')');

          // console.log(element);
          // console.log(myImgName)
  }
}

//------------------------------------------------------------------------------

scrollTo(x, y){
  window.scrollTo(x,y);
}

//------------------------------------------------------------------------------

scrollTop(){
  window.scrollTo(0,0);
}

//------------------------------------------------------------------------------

toggleSidebar(){
  // console.log("It was clicked");
  // $("#munchNavHeaderUl").slideToggle(300);
  if(this.dropNavOpen){
$("#munchNavHeaderUlCont").slideUp(500);

  }else{
    $("#munchNavHeaderUlCont").slideDown(500);
    $("#headerLogo").css("padding-bottom", "25px"); 
  }
this.dropNavOpen = !this.dropNavOpen;
  // $("#munchNavHeaderUl").css("display", "flex");

// console.log("What the fuck")
}

//------------------------------------------------------------------------------

hideSidebar(){
  $("#munchNavHeaderUlCont").slideUp(500);
  this.dropNavOpen = !this.dropNavOpen;
}

//------------------------------------------------------------------------------

isUserMobile(){
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    this.mobileUser = true;
    // console.log("Mobile User Boolean Is => " + this.mobileUser);
    return this.mobileUser;
  }else{
    console.log("User is on desktop");
    this.mobileUser = false;
    return this.mobileUser;
  }
}

//------------------------------------------------------------------------------

imageInNamePlacementFunc(elementIdBool:boolean, classOrId:string, imgPath:string, imageFileType:string,  secondImageOnFile?:boolean){

if(elementIdBool && secondImageOnFile){
  let myImageTextCont = $("#" + classOrId).text();
  $("#" + classOrId).css("background-image", "url(" + imgPath + myImageTextCont.replace(/ /g, '').toLowerCase() + "_1." + imageFileType + ")" )
}else if(elementIdBool){
  let myImageTextCont = $("#" + classOrId).text();
  $("#" + classOrId).css("background-image", "url(" + imgPath + myImageTextCont.replace(/ /g, '').toLowerCase() + "." + imageFileType + ")" )
} else if(!elementIdBool){
const myElementsArr = $("." + classOrId)

$.each( myElementsArr, function(i, val){

  let myImageTextCont = $(val).text();
  $(val).css("background-image", "url(" + imgPath + myImageTextCont.replace(/ /g, '').toLowerCase() + "." + imageFileType + ")" );

})

}

}

//------------------------------------------------------------------------------

onBackHistory(){
  window.history.back();
}

//------------------------------------------------------------------------------

capitalizeString(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

//------------------------------------------------------------------------------

capitalizeWholeStringSpaces(theReceivedString){
  let myString = theReceivedString;
  let str1 = myString.split(' ');
  let str2 = "";
  $.each(str1,(i)=>{
  str2 += this.capitalizeString($.trim(str1[i]))+' ';
  });
  return str2
}

//------------------------------------------------------------------------------

getMyBgColor(){
  return this.allWhiteBg;
}

//------------------------------------------------------------------------------

public placeMyImage(idOrClassName:string, imagePath:string, imageName:string, imageFileType:string, isElementId?:boolean){

  if(isElementId){
    const myElement = $("#" + idOrClassName);
  $(myElement).css("background-image", "url("+imagePath+myElement.textContent.toLowerCase().replace(/ /g, '')+imageFileType+")")
  }else if(!isElementId && imageName == 'nameNormal'){
    const myImageElemList:any =document.getElementsByClassName(idOrClassName);
  setTimeout(() => {
    for (let x = 0; x < myImageElemList.length; x++) {
      const element = myImageElemList[x];
      // console.log("ur("+imagePath+element.textContent.toLowerCase().replace(/ /g, '')+imageFileType+")")
      $(element).css("background-image", "url("+imagePath+element.textContent.toLowerCase().replace(/ /g, '')+imageFileType+")");
      // console.log("url("+imagePath+element.textContent.toLowerCase().replace(/ /g, '')+imageFileType+")")
    }
  });
  }else if(!isElementId && imageName == 'nameSecond'){
    const myImageElemList:any = document.getElementsByClassName(idOrClassName);
  setTimeout(() => {
    for (let x = 0; x < myImageElemList.length; x++) {
      const element = myImageElemList[x];
      $(element).css("background-image", "url("+imagePath+element.textContent.toLowerCase().replace(/ /g, '') + "_1"+imageFileType+")")
    } 
  });
  }
  
  
  }

//------------------------------------------------------------------------------

  isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}

//------------------------------------------------------------------------------

removeElement(elementItself) {
  // Removes an element from the document
  var element = elementItself;
  element.parentNode.removeChild(element);
}

// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................

}
