import { Component, OnInit, AfterViewInit, OnChanges, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {Title} from "@angular/platform-browser";

import { CountryContinentService } from './../../services/country-continent.service';
import { GlobalService } from './../../services/global.service';
import { RecipesService } from './../../services/recipes.service';
import * as $ from 'jquery'

@Component({
  selector: 'app-recipe-desktop',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.css', './responsive-recipe.component.css']
})

export class RecipeComponent implements OnInit, AfterViewInit, DoCheck{

  constructor(private myRoute:ActivatedRoute,
    private myRouter:Router,
    private  RecipesService :RecipesService,
    private GlobalService:GlobalService,
    private CountryContinentService:CountryContinentService,
    private TitleService:Title) { }

// *********************************************************************************************

  math:any= Math;
  recipeId :number;
  myRecipeObj:any;
  myRecipeContinent:string;
  needToolsBool:boolean = true;
  optToolsBool:boolean = false;
  needUtensilsArr:any[] = [];
  optUtensilsArr:any[] = [];

// *********************************************************************************************

ngOnInit() {



    if(this.myRoute.snapshot.params['recipeId'])   {
        this.recipeId = this.myRoute.snapshot.params['recipeId'];
        this.myRecipeObj = this.RecipesService.theRecipes[this.recipeId - 1]

        this.TitleService.setTitle("MunCH!  · " + this.GlobalService.capitalizeWholeStringSpaces(this.myRecipeObj.recipeName))

        for (let i = 0; i < this.CountryContinentService.countryContinentsArr.length; i++) {
          const obj = this.CountryContinentService.countryContinentsArr[i];
          let nameToTest = obj.country.replace(/ /g, '').toLowerCase();

          if(nameToTest == this.myRecipeObj.countryOfOrigin.replace(/ /g, '').toLowerCase() ){
            this.myRecipeContinent = obj.continent;
          }
        }


        this.myRoute.params.subscribe((theParams: Params)=>{
          this.recipeId = theParams['recipeId'];
        })
    }

}

//------------------------------------------------------------------------------

ngAfterViewInit(){

  this.continentSelectionFunc();
  this.imagePlacementInNameFunc("flagImgInfo", "../../../imgs/location/countries/", ".png");
  this.imagePlacementInNameFunc("mainRecipeImage", "../../../imgs/food/startRecipes/", ".jpg");
  this.mainImagePlacementFunc("mainBackimageCont", "./../../imgs/food/startRecipes/" ,this.myRecipeObj.recipeName);
  this.recipeIngredientImagePlacementFunc("recipeIngP", "./../../imgs/food/ingredients/");
  this.imagePlacementInNameFunc("recipeNutrition", "../../../imgs/food/nutrition/recipes/", ".png");
  this.utensilImagePlacementFunc();
  this.originalRecipeImageFunc();

  console.log($("#mainRecipeImage").text());


}
 
//------------------------------------------------------------------------------

ngDoCheck(){

}

//------------------------------------------------------------------------------

continentSelectionFunc(){

  const recipeContinentIntro = document.getElementsByClassName('recipeContinentP');
  // console.log(recipeContinentIntro)

  for (let x = 0; x < recipeContinentIntro.length; x++) {
    const element= recipeContinentIntro[x];
    let nameToTest = element.textContent.toLowerCase().replace(/ /g, '');

    for(let i = 0; i < this.CountryContinentService.countryContinentsArr.length; i++){
      const obj = this.CountryContinentService.countryContinentsArr[i];
      let countryToTest = obj.country.replace(/ /g, '').toLowerCase();

      if(countryToTest == nameToTest){
        element.textContent = obj.continent;
        let myContinentImg = element.previousElementSibling;
        $(myContinentImg).css("background-image", "url(../../../../imgs/location/continents/"+obj.continent.replace(/ /g, '').toLowerCase() +".png)")
      }
    }
  }

}

//------------------------------------------------------------------------------

imagePlacementInNameFunc( collectionClassName:string, imgPath:string, fileType:string ){

  let myElementsList = document.getElementsByClassName(collectionClassName);

  for (let i = 0; i < myElementsList.length; i++) {
    const element = myElementsList[i];
    const productFilePath = (imgPath + element.textContent + fileType).replace(/ /g, '');

    $(element).css("background-image", "url("+ productFilePath +")");
  }

}
//------------------------------------------------------------------------------

mainImagePlacementFunc(theBgId:string, thePicPath:string, theRecipeName:string){

  let myBgElem = document.getElementById(theBgId);
  $(myBgElem).css("background-image", "url(" + thePicPath + theRecipeName.toLowerCase().replace(/ /g, '') + "_1.jpg)");

}
//------------------------------------------------------------------------------

recipeIngredientImagePlacementFunc(ingNameElemClass:string, imgFilePath:string){
  let ingsArray:any= document.getElementsByClassName(ingNameElemClass);

  for (let i = 0; i < ingsArray.length; i++) {
    const element = ingsArray[i];
    const myIngName:string = element.textContent;
    const myImageElem = element.previousElementSibling;

    $(myImageElem).css("background-image", "url(" + imgFilePath + myIngName.replace(/ /g, '').toLowerCase() + ".jpg)");
    
  }
}

//------------------------------------------------------------------------------

utensilImagePlacementFunc(){
  
  setTimeout(() => {
    const utensils = $(".toolImg");


  if(this.needToolsBool){ //If need Ings

    for(let i = 0; i < utensils.length; i++){
      const currentUtensil = utensils[i];
      const myImageName = this.myRecipeObj.neededUtensils[i].name;

      // console.log(myImageName)
    
      $(currentUtensil).css("background-image", "url(../../../imgs/tools/" + myImageName.replace(/ /g, "") + ".png)");

    }
  
  }else{
    for(let i = 0; i < utensils.length; i++){
      const currentUtensil = utensils[i];
      const myImageName = this.myRecipeObj.optionalUtensils[i].name;
  
      $(currentUtensil).css("background-image", "url(../../../imgs/tools/" + myImageName.replace(/ /g, "")  + ".png)");
    }
  
  }

  });

}

//------------------------------------------------------------------------------

originalRecipeImageFunc(){

const myCreatorImageElem = $('#creatorImgInfo');


if(this.myRecipeObj.creatorImgUrl == 'undefined'){
$(myCreatorImageElem).css("background-image", "url(../../../../imgs/logos/user.png)");
console.log(this.myRecipeObj.creatorImgUrl)

}else{
  $(myCreatorImageElem).css('background-image', 'url('+this.myRecipeObj.creatorImgUrl +')')
}
  
}

//------------------------------------------------------------------------------

startCookingFunc(recipeCookingType:string){
console.log(recipeCookingType);

$('#recipeUnderInfo').slideUp(750);
$('#mainBackimageCont').slideUp(750);

setTimeout(() => {
  this.myRouter.navigate([recipeCookingType, this.myRecipeObj.recipeId])
  // this.myRouter.navigate(["hello"])

}, 800);

// [routerLink]="['/simple-recipe-d', myRecipeObj.recipeId]" 
}


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
