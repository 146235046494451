import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ing-normal-meas',
  templateUrl: './ing-normal-meas.component.html',
  styleUrls: ['./ing-normal-meas.component.css']
})
export class IngNormalMeasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
