import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'step-by-step',
  templateUrl: './step-by-step.component.html',
  styleUrls: ['./step-by-step.component.css']
})

export class StepByStepComponent implements OnInit {

  constructor() { }

// *********************************************************************************************

  @Input() recipeObj:any;

// *********************************************************************************************

ngOnInit() {

}

// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS

