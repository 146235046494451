import { GlobalService } from './../../services/global.service';
import { RecipesService } from './../../services/recipes.service';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

import * as $ from 'jquery'

@Component({
  selector: 'share-btns',
  templateUrl: './share-btns.component.html',
  styleUrls: ['./share-btns.component.css']
})
export class ShareBtnsComponent implements OnInit {

@Input('typeOfRecipe') myTypeOfRecipe:any;

  myRecipeId:any;
  whatsAppShare:any;
  faceBookShare:any;
  twitterShare:any;
  moreShare:any;
  recipeObj:any;
  linkCopiedBool:boolean=false;

  myShareObj:any = ['whatsAppShare', 'faceBookShare', 'twitterShare'];
  myShareImgs:any = ['whatsAppLogoImage', 'facebookLogoImage', 'twitterLogoImage'];

// *********************************************************************************************

  constructor(private ActivatedRoute:ActivatedRoute,
                      private RecipesService: RecipesService,
                      private GlobalService: GlobalService) { }


// *********************************************************************************************

  ngOnInit() {

    if(this.ActivatedRoute.snapshot.params['recipeId'])   {
      this.myRecipeId = this.ActivatedRoute.snapshot.params['recipeId'];
      this.recipeObj = this.RecipesService.theRecipes[this.myRecipeId - 1];
    }
    
    this.fillOutHrefs()

  }

//------------------------------------------------------------------------------

  fillOutHrefs(){
//What'sApp
              this.whatsAppShare = "whatsapp://send?text=Check%20out%20this%20" + 
              this.GlobalService.capitalizeWholeStringSpaces(this.recipeObj.recipeName).replace(/ /g, "%20") +
              "recipe%20on%20MunCH!%0D%0Dwww.onemunch.com/" + this.myTypeOfRecipe + "/" + this.myRecipeId;
//FaceBook
              this.faceBookShare = "https://www.facebook.com/sharer/sharer.php?u=www.onemunch.com/" + 
              this.myTypeOfRecipe + "/" + this.myRecipeId;
//Twitter
              this.twitterShare = 'http://twitter.com/share?url='+
              encodeURIComponent('www.onemunch.com/' +this.myTypeOfRecipe+ "/" + this.myRecipeId)+
              '&text='+encodeURIComponent("Check out this " + 
              this.GlobalService.capitalizeWholeStringSpaces(this.recipeObj.recipeName) + "recipe on MunCH!")
//Clipboard
              var dummy = document.createElement('input'),
              text = window.location.href;

              document.body.appendChild(dummy);
              dummy.value = text;
              dummy.select();
              document.execCommand('close');
              document.body.removeChild(dummy);


              for(let i = 0; i < this.myShareObj.length; i++){
                $("." + this.myShareImgs[i]).click(()=>{
                  window.open(this[this.myShareObj[i]])
                })

              }

  }

  copyLinkFunc(){
    //Clipboard
    let dummy = document.createElement('input'),
    text = window.location.href;
    console.log(text)

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);


    this.linkCopiedBool = true;

    setTimeout(() => {
    this.linkCopiedBool = false;
    }, 1750);

  }

// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS

