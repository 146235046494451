import { GlobalService } from './global.service';
import { RecipesService } from './recipes.service';
import { Injectable } from '@angular/core';
import * as $ from 'jquery'

@Injectable({
  providedIn: 'root'
})
export class IngredientsService { 

// VARIABLES


  myLastAmountUsed:number=undefined;


    public myNormalIngMeasurements:any=[
      {"abbreviation":"g", "name":"gram", "pluralName": "grams"},
      {"abbreviation":"lbs", "name":"pound", "pluralName": "pounds"},
      {"abbreviation":"tsp", "name":"teaspoon", "pluralName": "teaspoons"},
      {"abbreviation":"tbsp", "name":"tablespoon", "pluralName": "tablespoons"},
      {"abbreviation":"cup", "name":"cup", "pluralName": "cups"},
      {"abbreviation":"can", "name":"can", "pluralName": "cans"},
    ];


    public myIrregIngMeasurements:any=[
      {"abbreviation":"sprinkle", "name":"to sprinkle", "reasonOfUse": "When needing to sprinkle with an uncertain amount."},
      {"abbreviation":"totaste", "name":"to taste", "reasonOfUse": "Whenever the recipe varies on the ingredient depending taste."},
      {"abbreviation":"forfrying", "name":"for frying", "reasonOfUse": "When needing to fry something. Amounts may vary."},

      
    ]

// *********************************************************************************************

  constructor(private RecipesService:RecipesService,
                      private GlobalService:GlobalService) { }

// *********************************************************************************************
//FUNCTIONS


  onPplInputChange(myGroupClassName:string, myServingsMultiplier:number, myIngsObj:any[]){
    
    this.fixIngredientAmountFunc(myGroupClassName);
    this.adjustIngredientAmountFunc(myGroupClassName, myServingsMultiplier, myIngsObj);
    this.fractionMyIngredientMeasurements(myGroupClassName);

      this.myLastAmountUsed = myServingsMultiplier;
  }


fixIngredientAmountFunc(groupClassName:string){

  let myIngMeasures = $("." + groupClassName);

  for(let i = 0; i < myIngMeasures.length; i++){

  let myFixableString = myIngMeasures[i].textContent;
  let myFixedVal:string = undefined;

  if(myFixableString.includes('⅛')){
    myFixedVal = myFixableString.replace(/⅛/g, '.125');
  }else if(myFixableString.includes('¼')){
    myFixedVal = myFixableString.replace(/¼/g, '.25');
  }else if(myFixableString.includes('⅓')){
    myFixedVal = myFixableString.replace(/⅓/g, '.33');
  }else if(myFixableString.includes('½')){
    myFixedVal = myFixableString.replace(/½/g, '.5');
  }else if(myFixableString.includes('⅔')){
    myFixedVal = myFixableString.replace(/⅔/g, '.66');
  }else if(myFixableString.includes('¾')){
    myFixedVal = myFixableString.replace(/¾/g, '.75');
  }else{
    // console.log("Nothing to do here...");
    // console.log("FixableString => " + myFixableString)
  }

    if(myFixedVal !== undefined){
      myIngMeasures[i].textContent = myFixedVal;
    }else if(isNaN(myFixableString)){
      console.error("ARRIVED 452 Nothing to do here...");
      console.log("FixableString => " + myFixableString)
    }

}

}

//------------------------------------------------------------------------------

fractionMyIngredientMeasurements(groupClassName:string){

  let myIngMeasures = $("." + groupClassName);
  for(let i = 0; i < myIngMeasures.length; i++){

    let stringToTest:any=  parseFloat(myIngMeasures[i].textContent);
    // let stringToTest:any = Math.round((myString+ Number.EPSILON) * 100) / 100;
    let numToTest:any = stringToTest;
    let floatToPlace:string=undefined;
    let numToPlace:any;


    // console.log(groupClassName  + " ∂∂∂");
    // console.log(    myIngMeasures[i]);


// console.log(myIngMeasures[i].nextElementSibling);
// console.log(stringToTest);

    if(this.GlobalService.isFloat(stringToTest) &&  stringToTest > 1){
      numToPlace = Math.floor(stringToTest);
      numToTest = stringToTest - numToPlace;

      // console.log("My num = " + stringToTest);

      // console.log("Whole num = " + numToPlace);
      // console.log("Float num = " + numToTest);
    }

    if(numToTest !== 1 && numToTest < 1){
      if(numToTest > 0 && numToTest <= .179 ){ //if number is between 0 and .79 [rounded to .125] (Mediary point between .125 and .25)
        floatToPlace =  '⅛';
      }else if(numToTest >= .187 && numToTest <= .289 ){//if number is between .187 and .289 [rounded to .25] (Mediary point between .25 and .33)
        floatToPlace =  '¼';
      }else if(numToTest >= .290 && numToTest <= .415 ){//if number is between .290 and .415 [rounded to .33 (⅓)] (Mediary point between .33 and .5)
        floatToPlace =  '⅓';
      }else if(numToTest >= .416 && numToTest <= .582 ){//if number is between .416 and .582 [rounded to .5] (Mediary point between .5 and .66)
        floatToPlace =  '½';
      }else if(numToTest >= .583 && numToTest <= .707 ){//if number is between .583 and .707 [rounded to .66 (⅔)] (Mediary point between .66 and .75)
        floatToPlace =  '⅔';
      }else if(numToTest >= .708 && numToTest <= .874 ){//if number is between .708 and .824 [rounded to .75] (Mediary point between .75 and 1)
        floatToPlace =  '¾';
      }else if(numToTest >= .875 && numToTest <= .99 ){//if number is between .875 and .99 [rounded to 1] (Greater mediary point between .75 and 1)
        numToPlace =  1;
      }
    }else{
      // console.log("∂∂∂");
      // console.log(numToTest)
      numToPlace = parseInt(numToTest)
    }


      
    if(this.GlobalService.isFloat(stringToTest) &&  stringToTest > 1){ //If a Float bigger than 1
      // console.log(myIngMeasures[i].parentElement)
      // console.log("SHIT!")
      myIngMeasures[i].innerHTML = numToPlace + floatToPlace;
    }else if(floatToPlace !== undefined){ //If a Float
      // console.log(myIngMeasures[i].parentElement)
      myIngMeasures[i].innerHTML = floatToPlace;
    }else{ //If a Number
        myIngMeasures[i].innerHTML = numToTest;
      }

     
      }

      }

//------------------------------------------------------------------------------

      adjustIngredientAmountFunc(groupClassName:string, servingsMultiplier:number, ingsObj:any[]){
        
        const myAmountConts = $("." + groupClassName);

        //  console.log("∂∂∂")
        // console.log(myAmountConts);
        // console.log(ingsObj)


        for(let i = 0; i < myAmountConts.length; i++){

          if(ingsObj[i].amountPerPerson !== undefined){
            let amountCont = myAmountConts[i];
          amountCont.textContent = ingsObj[i].amountPerPerson * servingsMultiplier;
          
          }
          // console.log("My obj => " + ingsObj[i].amountPerPerson);
          // console.log("My mtplyer => " + servingsMultiplier);


          
        }


      }


// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS

