import { GlobalService } from './../../services/global.service';
import { IngredientsService } from './../../services/ingredients.service';
import { Component, OnInit, Input, AfterViewInit, OnChanges } from '@angular/core';
import { type } from 'os';

@Component({
  selector: 'app-ingredients',
  templateUrl: './ingredients.component.html',
  styleUrls: ['./ingredients.component.css']
})
export class IngredientsComponent implements OnInit, AfterViewInit, OnChanges {
//LOCAL VARIABLES

  @Input('ingredientsObj') ingredientsObjPassed: any;
  @Input('servingsAmount') peopleAmt: any;
  @Input('optionalIngredients') optionalIngsVar:any;
  
  irregMeasurements:any=this.IngredientsService.myIrregIngMeasurements;
  myIngredientsInfo:any=[]
  myItemMeasuredIngredients:any = [];
  myItemOfMeasuredIngredients:any = [];
  myDetailMeasuredIngredients:any = [];
  myIrregMeasuredIngredients:any = [];

  

// *********************************************************************************************

  constructor(private IngredientsService: IngredientsService,
                      private GlobalService: GlobalService) { }

// *********************************************************************************************
//LIFECYCLE HOOKS

  ngOnInit() {

    // console.log(this.optionalIngsVar)

    for (let i = 0; i < this.ingredientsObjPassed.length; i++) {
      const ingredient = this.ingredientsObjPassed[i];

// console.log(ingredient.name + " => " + ingredient.amountMeasure)

      //If ingredient is DetailedMeasuredIngredient
      if(ingredient.amountDetail){
      this.myIngredientsInfo.push({'name': ingredient.name, "position": i, "type": "detailMeasurement"});
      this.myDetailMeasuredIngredients.push(this.ingredientsObjPassed[i])
      }
      //If ingredient is IrregularMeasuredIngredient
      else if(this.isIrregIngMeasure(ingredient.amountMeasure, ingredient.name) ){
        this.myIngredientsInfo.push({'name': ingredient.name, "position": i, "type": "itemMeasurement"});
        this.myIrregMeasuredIngredients.push(this.ingredientsObjPassed[i]);
      }
      //If ingredient is ItemMeasuredIngredient
      else if(ingredient.name.includes(ingredient.amountMeasure) && 
        !this.isClassicIngMeasure(ingredient.amountMeasure, ingredient.name)&&
        !this.isIrregIngMeasure(ingredient.amountMeasure, ingredient.name) ){
        this.myIngredientsInfo.push({'name': ingredient.name, "position": i, "type": "itemMeasurement"});
        this.myItemMeasuredIngredients.push(this.ingredientsObjPassed[i]);
      }
      //If ingredient is ItemOfMeasuredIngredient
      else if( !this.isIrregIngMeasure(ingredient.amountMeasure, ingredient.name)  ){
        this.myIngredientsInfo.push({'name': ingredient.name, "position": i, "type": "itemOfMeasurement"});
        this.myItemOfMeasuredIngredients.push(this.ingredientsObjPassed[i]);
      }else{
        console.error('NOPE!')
      }
    }
  }

//------------------------------------------------------------------------------

  ngAfterViewInit(){

    this.GlobalService.placeMyImage('ingredientDispImg', './../../../imgs/food/ingredients/',  'nameNormal', ".jpg", false);
     
    }

    ngOnChanges(){
      // console.log(this.peopleAmt)
    }
    
  

// *********************************************************************************************
//LOCAL FUNCTIONS

isClassicIngMeasure(ingredientMeasure:string, ingredientName:string){

  for (let i = 0; i < this.IngredientsService.myNormalIngMeasurements.length; i++) {
    const typeOfOriginalMeasurement = this.IngredientsService.myNormalIngMeasurements[i];

    // console.log(ingredientName + "(" + ingredientMeasure + ")" + " VS " + typeOfOriginalMeasurement.abbreviation)

    if(ingredientMeasure === typeOfOriginalMeasurement.abbreviation &&
       ingredientMeasure.length == typeOfOriginalMeasurement.abbreviation.length){
      // console.log(typeOfOriginalMeasurement.abbreviation + " VS " + ingredientMeasure)
      return true
    }


    if(i == this.IngredientsService.myNormalIngMeasurements.length - 1 ){
      return false
    }
    
  }

}

//------------------------------------------------------------------------------

isIrregIngMeasure(ingredientMeasure:string, ingredientName:string){


  for (let i = 0; i < this.IngredientsService.myIrregIngMeasurements.length; i++) {
    const typeOfIrregMeasurement = this.IngredientsService.myIrregIngMeasurements[i];


    if(ingredientMeasure == typeOfIrregMeasurement.abbreviation &&
       ingredientMeasure.length == typeOfIrregMeasurement.abbreviation.length){
      return true
    }


    if(i == this.IngredientsService.myIrregIngMeasurements.length ){
      return false
    }
    
  }

}

//------------------------------------------------------------------------------










// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS

