import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryContinentService {

  constructor() { }


  countryContinentsArr:any[]=[





    {
        "country": "Afghanistan",
        "continent": "Asia"
    },
    {
        "country": "cavemen",
        "continent": "Earth"
    },
    {
        "country": "Albania",
        "continent": "Europe"
    },
    {
        "country": "Algeria",
        "continent": "Africa"
    },
    {
        "country": "American Samoa",
        "continent": "Australia"
    },
    {
        "country": "Andorra",
        "continent": "Europe"
    },
    {
        "country": "Angola",
        "continent": "Africa"
    },
    {
        "country": "Anguilla",
        "continent": "North America"
    },
    {
        "country": "Antarctica",
        "continent": "Antarctica"
    },
    {
        "country": "Antigua and Barbuda",
        "continent": "North America"
    },
    {
        "country": "Argentina",
        "continent": "South America"
    },
    {
        "country": "Armenia",
        "continent": "Asia"
    },
    {
        "country": "Aruba",
        "continent": "North America"
    },
    {
        "country": "Australia",
        "continent": "Australia"
    },
    {
        "country": "Austria",
        "continent": "Europe"
    },
    {
        "country": "Azerbaijan",
        "continent": "Asia"
    },
    {
        "country": "Bahamas",
        "continent": "North America"
    },
    {
        "country": "Bahrain",
        "continent": "Asia"
    },
    {
        "country": "Bangladesh",
        "continent": "Asia"
    },
    {
        "country": "Barbados",
        "continent": "North America"
    },
    {
        "country": "Belarus",
        "continent": "Europe"
    },
    {
        "country": "Belgium",
        "continent": "Europe"
    },
    {
        "country": "Belize",
        "continent": "North America"
    },
    {
        "country": "Benin",
        "continent": "Africa"
    },
    {
        "country": "Bermuda",
        "continent": "North America"
    },
    {
        "country": "Bhutan",
        "continent": "Asia"
    },
    {
        "country": "Bolivia",
        "continent": "South America"
    },
    {
        "country": "Bosnia and Herzegovina",
        "continent": "Europe"
    },
    {
        "country": "Botswana",
        "continent": "Africa"
    },
    {
        "country": "Bouvet Island",
        "continent": "Antarctica"
    },
    {
        "country": "Brazil",
        "continent": "South America"
    },
    {
        "country": "British Indian Ocean Territory",
        "continent": "Africa"
    },
    {
        "country": "Brunei",
        "continent": "Asia"
    },
    {
        "country": "Bulgaria",
        "continent": "Europe"
    },
    {
        "country": "Burkina Faso",
        "continent": "Africa"
    },
    {
        "country": "Burundi",
        "continent": "Africa"
    },
    {
        "country": "Cambodia",
        "continent": "Asia"
    },
    {
        "country": "Cameroon",
        "continent": "Africa"
    },
    {
        "country": "Canada",
        "continent": "North America"
    },
    {
        "country": "Cape Verde",
        "continent": "Africa"
    },
    {
        "country": "Cayman Islands",
        "continent": "North America"
    },
    {
        "country": "Central African Republic",
        "continent": "Africa"
    },
    {
        "country": "Chad",
        "continent": "Africa"
    },
    {
        "country": "Chile",
        "continent": "South America"
    },
    {
        "country": "China",
        "continent": "Asia"
    },
    {
        "country": "Christmas Island",
        "continent": "Australia"
    },
    {
        "country": "Cocos (Keeling) Islands",
        "continent": "Australia"
    },
    {
        "country": "Colombia",
        "continent": "South America",
        "countryDescription": "Colombia is a country in South America with a simple, yet a distinct gastronomic variety of dishes. From the hills of Medellin, the birthplace of the Bandeja Paisa & Pablo Escobar. To Santa Marta, the coast of Colombia where they go as far as Cooking Coconut rice with Coca-Cola! Whichever dish you pick to cook from Colombia, you are sure to get the full essence of the country."
    },
    {
        "country": "Comoros",
        "continent": "Africa"
    },
    {
        "country": "Congo",
        "continent": "Africa"
    },
    {
        "country": "Cook Islands",
        "continent": "Australia"
    },
    {
        "country": "Costa Rica",
        "continent": "North America"
    },
    {
        "country": "Croatia",
        "continent": "Europe"
    },
    {
        "country": "Cuba",
        "continent": "North America"
    },
    {
        "country": "Cyprus",
        "continent": "Asia"
    },
    {
        "country": "Czech Republic",
        "continent": "Europe"
    },
    {
        "country": "Denmark",
        "continent": "Europe"
    },
    {
        "country": "Djibouti",
        "continent": "Africa"
    },
    {
        "country": "Dominica",
        "continent": "North America"
    },
    {
        "country": "Dominican Republic",
        "continent": "North America"
    },
    {
        "country": "East Timor",
        "continent": "Asia"
    },
    {
        "country": "Ecuador",
        "continent": "South America"
    },
    {
        "country": "Egypt",
        "continent": "Africa"
    },
    {
        "country": "El Salvador",
        "continent": "North America"
    },
    {
        "country": "England",
        "continent": "Europe",
        "countryDescription":"English cuisine has distinctive attributes of its own, but also shares much with wider British cuisine, partly through the importation of ingredients and ideas from the Americas, China, and India. Traditionally, English food uses lamb, beef, pork, chicken and fish as its feature item. The meat is then accompanied by potatoes (in various forms and one vegetable."
    },
    {
        "country": "Equatorial Guinea",
        "continent": "Africa"
    },
    {
        "country": "Eritrea",
        "continent": "Africa"
    },
    {
        "country": "Estonia",
        "continent": "Europe"
    },
    {
        "country": "Ethiopia",
        "continent": "Africa"
    },
    {
        "country": "Falkland Islands",
        "continent": "South America"
    },
    {
        "country": "Faroe Islands",
        "continent": "Europe"
    },
    {
        "country": "Fiji Islands",
        "continent": "Australia"
    },
    {
        "country": "Finland",
        "continent": "Europe"
    },
    {
        "country": "France",
        "continent": "Europe"
    },
    {
        "country": "French Guiana",
        "continent": "South America"
    },
    {
        "country": "French Polynesia",
        "continent": "Australia"
    },
    {
        "country": "French Southern territories",
        "continent": "Antarctica"
    },
    {
        "country": "Gabon",
        "continent": "Africa"
    },
    {
        "country": "Gambia",
        "continent": "Africa"
    },
    {
        "country": "Georgia",
        "continent": "Asia"
    },
    {
        "country": "Germany",
        "continent": "Europe"
    },
    {
        "country": "Ghana",
        "continent": "Africa"
    },
    {
        "country": "Gibraltar",
        "continent": "Europe"
    },
    {
        "country": "Greece",
        "continent": "Europe"
    },
    {
        "country": "Greenland",
        "continent": "North America"
    },
    {
        "country": "Grenada",
        "continent": "North America"
    },
    {
        "country": "Guadeloupe",
        "continent": "North America"
    },
    {
        "country": "Guam",
        "continent": "Australia"
    },
    {
        "country": "Guatemala",
        "continent": "North America"
    },
    {
        "country": "Guinea",
        "continent": "Africa"
    },
    {
        "country": "Guinea-Bissau",
        "continent": "Africa"
    },
    {
        "country": "Guyana",
        "continent": "South America"
    },
    {
        "country": "Haiti",
        "continent": "North America"
    },
    {
        "country": "Heard Island and McDonald Islands",
        "continent": "Antarctica"
    },
    {
        "country": "Holy See (Vatican City State)",
        "continent": "Europe"
    },
    {
        "country": "Honduras",
        "continent": "North America"
    },
    {
        "country": "Hong Kong",
        "continent": "Asia"
    },
    {
        "country": "Hungary",
        "continent": "Europe"
    },
    {
        "country": "Iceland",
        "continent": "Europe"
    },
    {
        "country": "India",
        "continent": "Asia",
        "countryDescription":"Indian cuisine is all about the spices. With all the sumptuous dishes, intricate ingredients and tongue tickling flavors, it is one of the most popular cuisines in the world. From tandoori chicken to palak paneer, the recipes are relatively simple. You can even learn to make your own Indian dishes from the comodity of your home! Imagine tasking your first chicken curry."
    },
    {
        "country": "Indonesia",
        "continent": "Asia"
    },
    {
        "country": "Iran",
        "continent": "Asia"
    },
    {
        "country": "Iraq",
        "continent": "Asia"
    },
    {
        "country": "Ireland",
        "continent": "Europe"
    },
    {
        "country": "Israel",
        "continent": "Asia"
    },
    {
        "country": "Italy",
        "continent": "Europe",
        "countryDescription":"Italian cuisine has developed through centuries of social and economic changes. Italian food ranges in complexity, from a simple dish of spagethi to a more complex dish such as a colzone. It's fundamental to understand that talian food is more than just pizza and spaghetti. There’s a wide range of ingredients, flavors, and dishes to experiment with in your own home."
    },
    {
        "country": "Ivory Coast",
        "continent": "Africa"
    },
    {
        "country": "Jamaica",
        "continent": "North America"
    },
    {
        "country": "Japan",
        "continent": "Asia"
    },
    {
        "country": "Jordan",
        "continent": "Asia"
    },
    {
        "country": "Kazakhstan",
        "continent": null
    },
    {
        "country": "Kenya",
        "continent": "Africa"
    },
    {
        "country": "Kiribati",
        "continent": "Australia"
    },
    {
        "country": "Kuwait",
        "continent": "Asia"
    },
    {
        "country": "Kyrgyzstan",
        "continent": "Asia"
    },
    {
        "country": "Laos",
        "continent": "Asia"
    },
    {
        "country": "Latvia",
        "continent": "Europe"
    },
    {
        "country": "Lebanon",
        "continent": "Asia"
    },
    {
        "country": "Lesotho",
        "continent": "Africa"
    },
    {
        "country": "Liberia",
        "continent": "Africa"
    },
    {
        "country": "Libyan Arab Jamahiriya",
        "continent": "Africa"
    },
    {
        "country": "Liechtenstein",
        "continent": "Europe"
    },
    {
        "country": "Lithuania",
        "continent": "Europe"
    },
    {
        "country": "Luxembourg",
        "continent": "Europe"
    },
    {
        "country": "Macao",
        "continent": "Asia"
    },
    {
        "country": "North Macedonia",
        "continent": "Europe"
    },
    {
        "country": "Madagascar",
        "continent": "Africa"
    },
    {
        "country": "Malawi",
        "continent": "Africa"
    },
    {
        "country": "Malaysia",
        "continent": "Asia"
    },
    {
        "country": "Maldives",
        "continent": "Asia"
    },
    {
        "country": "Mali",
        "continent": "Africa"
    },
    {
        "country": "Malta",
        "continent": "Europe"
    },
    {
        "country": "Marshall Islands",
        "continent": "Australia"
    },
    {
        "country": "Martinique",
        "continent": "North America"
    },
    {
        "country": "Mauritania",
        "continent": "Africa"
    },
    {
        "country": "Mauritius",
        "continent": "Africa"
    },
    {
        "country": "Mayotte",
        "continent": "Africa"
    },
    {
        "country": "Mexico",
        "continent": "North America",
        "countryDescription":"Mexican cuisine began about 9000 years ago, when agricultural communities such as the Maya formed, domesticating maize, creating the standard process of corn nixtamalization, and establishing their foodways. Successive waves of other Mesoamerican groups brought with them their own cooking methods. Ranging from tacos, quesadillas, burritos, enchiladas to guagamole and salsa."
    },
    {
        "country": "Micronesia, Federated States of",
        "continent": null
    },
    {
        "country": "Moldova",
        "continent": "Europe"
    },
    {
        "country": "Monaco",
        "continent": "Europe"
    },
    {
        "country": "Mongolia",
        "continent": "Asia"
    },
    {
        "country": "Montserrat",
        "continent": "North America"
    },
    {
        "country": "Morocco",
        "continent": "Africa"
    },
    {
        "country": "Mozambique",
        "continent": "Africa"
    },
    {
        "country": "Myanmar",
        "continent": "Asia"
    },
    {
        "country": "Namibia",
        "continent": "Africa"
    },
    {
        "country": "Nauru",
        "continent": "Australia"
    },
    {
        "country": "Nepal",
        "continent": "Asia"
    },
    {
        "country": "Netherlands",
        "continent": "Europe"
    },
    {
        "country": "Netherlands Antilles",
        "continent": "North America"
    },
    {
        "country": "New Caledonia",
        "continent": "Australia"
    },
    {
        "country": "New Zealand",
        "continent": "Australia"
    },
    {
        "country": "Nicaragua",
        "continent": "North America"
    },
    {
        "country": "Niger",
        "continent": "Africa"
    },
    {
        "country": "Nigeria",
        "continent": "Africa"
    },
    {
        "country": "Niue",
        "continent": "Australia"
    },
    {
        "country": "Norfolk Island",
        "continent": "Australia"
    },
    {
        "country": "North Korea",
        "continent": "Asia"
    },
    {
        "country": "Northern Ireland",
        "continent": null
    },
    {
        "country": "Northern Mariana Islands",
        "continent": "Australia"
    },
    {
        "country": "Norway",
        "continent": "Europe"
    },
    {
        "country": "Oman",
        "continent": "Asia"
    },
    {
        "country": "Pakistan",
        "continent": "Asia"
    },
    {
        "country": "Palau",
        "continent": "Australia"
    },
    {
        "country": "Palestine",
        "continent": "Asia"
    },
    {
        "country": "Panama",
        "continent": "North America"
    },
    {
        "country": "Papua New Guinea",
        "continent": "Australia"
    },
    {
        "country": "Paraguay",
        "continent": "South America"
    },
    {
        "country": "Peru",
        "continent": "South America",
        "countryDescription":"Peruvian Cuisine reflects the country’s history, with a variety that is unique in the world.It reflects local practices and ingredients including influences from the indigenous population, including the Inca, and cuisines brought in with colonizers and immigrants from Europe, Asia and West Africa. Dishes like Ceviche, Aji de Gallina, and Lomo Saltado mark Peru's gastronomic Essence"
    },
    {
        "country": "Philippines",
        "continent": "Asia"
    },
    {
        "country": "Pitcairn",
        "continent": "Australia"
    },
    {
        "country": "Poland",
        "continent": "Europe"
    },
    {
        "country": "Portugal",
        "continent": "Europe",
        "countryDescription":"Portuguese are among the nations that consume more fish in the world. Therefore, Portuguese cuisine relies heavily on the Atlantic Ocean and the produce grown throughout the country. A journey through Portugal will bring you the likes of whole octopus slathered in olive oil, soups bobbing with beans and meat and francesinha sandwiches, puffed up with coin-size chorizo rounds."
    },
    {
        "country": "Puerto Rico",
        "continent": "North America"
    },
    {
        "country": "Qatar",
        "continent": "Asia"
    },
    {
        "country": "Reunion",
        "continent": "Africa"
    },
    {
        "country": "Romania",
        "continent": "Europe"
    },
    {
        "country": "Russian Federation",
        "continent": "Europe"
    },
    {
        "country": "Rwanda",
        "continent": "Africa"
    },
    {
        "country": "Saint Helena",
        "continent": "Africa"
    },
    {
        "country": "Saint Kitts and Nevis",
        "continent": "North America"
    },
    {
        "country": "Saint Lucia",
        "continent": "North America"
    },
    {
        "country": "Saint Pierre and Miquelon",
        "continent": "North America"
    },
    {
        "country": "Saint Vincent and the Grenadines",
        "continent": "North America"
    },
    {
        "country": "Samoa",
        "continent": "Australia"
    },
    {
        "country": "San Marino",
        "continent": "Europe"
    },
    {
        "country": "Sao Tome and Principe",
        "continent": "Africa"
    },
    {
        "country": "Saudi Arabia",
        "continent": "Asia"
    },
    {
        "country": "Scotland",
        "continent": "Europe",
        "countryDescription":"Scottish cuisine has distinctive attributes and recipes of its own, but shares much with wider British and European cuisine as a result of local and foreign influences, both ancient and modern. Scotland’s national dish is haggis, a savoury meat pudding, and it’s traditionally accompanied by mashed potatoes, turnips (known as ‘neeps’) and a whisky sauce."
    },
    {
        "country": "Senegal",
        "continent": "Africa"
    },
    {
        "country": "Seychelles",
        "continent": "Africa"
    },
    {
        "country": "Sierra Leone",
        "continent": "Africa"
    },
    {
        "country": "Singapore",
        "continent": "Asia"
    },
    {
        "country": "Slovakia",
        "continent": "Europe"
    },
    {
        "country": "Slovenia",
        "continent": "Europe"
    },
    {
        "country": "Solomon Islands",
        "continent": "Australia"
    },
    {
        "country": "Somalia",
        "continent": "Africa"
    },
    {
        "country": "South Africa",
        "continent": "Africa"
    },
    {
        "country": "South Georgia and the South Sandwich Islands",
        "continent": "Antarctica"
    },
    {
        "country": "South Korea",
        "continent": "Asia"
    },
    {
        "country": "South Sudan",
        "continent": "Africa"
    },
    {
        "country": "Spain",
        "continent": "Europe",
        "countryDescription":"Spanish cuisine is heavily influenced by historical processes that shaped local culture and society in some of Europe's Iberian Peninsula territories. Geography and climate had great influence on cooking methods and available ingredients. This is specifically represented by ingredients included in the most iconic dish of spain, the Paella."
    },
    {
        "country": "SriLanka",
        "continent": null
    },
    {
        "country": "Sudan",
        "continent": "Africa"
    },
    {
        "country": "Suriname",
        "continent": "South America"
    },
    {
        "country": "Svalbard and Jan Mayen",
        "continent": "Europe"
    },
    {
        "country": "Swaziland",
        "continent": "Africa"
    },
    {
        "country": "Sweden",
        "continent": "Europe"
    },
    {
        "country": "Switzerland",
        "continent": "Europe"
    },
    {
        "country": "Syria",
        "continent": "Asia"
    },
    {
        "country": "Tajikistan",
        "continent": "Asia"
    },
    {
        "country": "Tanzania",
        "continent": "Africa"
    },
    {
        "country": "Thailand",
        "continent": "Asia"
    },
    {
        "country": "The Democratic Republic of Congo",
        "continent": null
    },
    {
        "country": "Togo",
        "continent": "Africa"
    },
    {
        "country": "Tokelau",
        "continent": "Australia"
    },
    {
        "country": "Tonga",
        "continent": "Australia"
    },
    {
        "country": "Trinidad and Tobago",
        "continent": "North America"
    },
    {
        "country": "Tunisia",
        "continent": "Africa"
    },
    {
        "country": "Turkey",
        "continent": "Asia"
    },
    {
        "country": "Turkmenistan",
        "continent": "Asia"
    },
    {
        "country": "Turks and Caicos Islands",
        "continent": "North America"
    },
    {
        "country": "Tuvalu",
        "continent": "Australia"
    },
    {
        "country": "Uganda",
        "continent": "Africa"
    },
    {
        "country": "Ukraine",
        "continent": "Europe"
    },
    {
        "country": "United Arab Emirates",
        "continent": "Asia"
    },
    {
        "country": "United Kingdom",
        "continent": "Europe"
    },
    {
        "country": "United States",
        "continent": "North America"
    },
    {
        "country": "United States Minor Outlying Islands",
        "continent": "Australia"
    },
    {
        "country": "Uruguay",
        "continent": "South America"
    },
    {
        "country": "Uzbekistan",
        "continent": "Asia"
    },
    {
        "country": "Vanuatu",
        "continent": "Australia"
    },
    {
        "country": "Venezuela",
        "continent": "South America"
    },
    {
        "country": "Vietnam",
        "continent": "Asia"
    },
    {
        "country": "Virgin Islands, British",
        "continent": null
    },
    {
        "country": "Virgin Islands, U.S.",
        "continent": null
    },
    {
        "country": "Wales",
        "continent": null
    },
    {
        "country": "Wallis and Futuna",
        "continent": "Australia"
    },
    {
        "country": "Western Sahara",
        "continent": "Africa"
    },
    {
        "country": "Yemen",
        "continent": "Asia"
    },
    {
        "country": "Yugoslavia",
        "continent": "Europe"
    },
    {
        "country": "Zambia",
        "continent": "Africa"
    },
    {
        "country": "Zimbabwe",
        "continent": "Africa"
    }
]
}
