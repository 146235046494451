import { RecipesService } from './../recipes.service';
import { GlobalService } from './../global.service';
import { Injectable, OnInit } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MobileRecipeCategoryGuard implements CanActivate {

  constructor(private GlobalService:GlobalService,
  private RecipesService:RecipesService,
  private myRouter:Router,
  private myRoute:ActivatedRoute ){}


 

canActivate(route:ActivatedRouteSnapshot, routerState:RouterStateSnapshot)
:Observable<boolean> | Promise<boolean> | boolean{

    if(this.GlobalService.isUserMobile()){
      this.myRouter.navigate(['/mobileRecipes']);
      this.myRouter.navigate(['/mobileRecipes', "categories", this.RecipesService.myActiveCategoryClicked]);
    }else{
      return true
    }



}


  

// *********************************************************************************************
//------------------------------------------------------------------------------
//...........................................................................................
}//END OF CLASS
